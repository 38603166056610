@import "./variables";

@mixin common-styles {
  --background: transparent;
}

@mixin bau-slim-searchbar {
  &.md {
    @include common-styles;
    --box-shadow: #{$bmSlimCardBoxShadow};
    padding-left: 2px;
    padding-right: 2px;
    padding-inline-start: 2px;
    padding-inline-end: 2px;

    padding-top: 4px;
    padding-bottom: 4px;

    .searchbar-input {
      padding-top: 3px;
      padding-bottom: 3px;
      padding-inline-start: 35px;
      padding-inline-end: 30px;
    }

    .searchbar-search-icon {
      left: 10px;
    }

    .searchbar-clear-button {
      right: 5px;
    }

    .searchbar-input:placeholder-shown {
      padding-inline-end: 20px;
    }

    .searchbar-search-icon {
      top: 8px;
    }
  }

  &.ios {
    @include common-styles;
    height: 52px;
    padding: 4px 2px;

    .searchbar-input-container {
        height: 45px;
    }

    .searchbar-input:placeholder-shown {
      padding-inline-end: 20px;
    }

    &.searchbar-left-aligned {
      .searchbar-search-icon {
        left: 10px;
      }
      
      .searchbar-input {
        padding-left: 38px;

        @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
          & {
            padding-left: unset;
            padding-inline-start: 38px;
          }
        }
      }
    }
  }
}
