@import "color";
@import "mixins";

.gantt_container,
.gantt_tooltip,
.gantt-info,
.gantt_modal_box,
.gantt_cal_light,
.gantt_cal_light select,
.gantt_cal_ltext textarea,
.gantt_custom_button,
.gantt_drag_marker {
  font-family: var(--ion-font-family, inherit);
}

.gantt_task_line {
  background-color: $bmPrimary;
  border-color: $bmBlue2;
}

.gantt_task_line.gantt_milestone {
  background-color: $bmPrimary;
}

.gantt_btn_set.gantt_save_btn_set {
  background: $bmPrimary;
  text-shadow: 0 -1px 0 $bmBlue2;
}

.gantt_popup_button.gantt_ok_button {
  background: $bmPrimary;
  text-shadow: 0 -1px 0 $bmBlue2;
}

.gantt_popup_button.gantt_delete_button {
  background: $bmPrimary;
  text-shadow: 0 -1px 0 $bmBlue2;
}

.gantt_link_target {
  box-shadow: 0px 0px 3px $bmPrimary;
}

.gantt_tree_icon,
.gantt_file {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-right: 2px;

  &.protocol_entry_INFO {
    background-image: url("/assets/images/bm-status-info.svg");
  }

  &.protocol_entry_CONTINUOUS_INFO {
    background-image: url("/assets/images/bm-status-continuous-info.svg");
  }

  &.protocol_entry_DONE {
    background-image: url("/assets/images/bm-status-done.svg");
  }

  &.protocol_entry_ON_HOLD {
    background-image: url("/assets/images/bm-status-on-hold.svg");
  }

  &.protocol_entry_OPEN {
    background-image: url("/assets/images/bm-status-open.svg");
  }
  // if a new status is being added here or the icons change, make sure to change the icons in gantt-shared.ts as well (there are image-urls of the svg files).
}

.gantt_tree_folder {
  width: 15px;
  height: 15px;
  background: no-repeat;
  margin-top: 10px;
  margin-right: 2px;

  &.protocol_folder {
    background-image: url("/assets/images/protocol.svg");
  }

  &.task_protocol_folder {
    background-image: url("/assets/images/task_protocol.svg");
  }

  &.project_folder {
    background-image: url("/assets/images/project.svg");
  }
}

.constructionSchedule .task-subtask .gantt_add {
  display: none;
}

.constructionSchedule .task-milestone .gantt_add {
  display: none;
}

.gantt_marker {
  &.today {
    background-color: $bmTextDanger;
  }

  &.project-start {
    background-color: $bmBlue2;
  }

  &.project-end {
    background-color: $bmBlue2;
  }
}

.gantt_task_cell.week_end {
  background-color: #EFF5FD;
}

.gantt_task_cell.day_off {
  background-color: rgba($bmTextDanger, .80);
}

.gantt_container .gantt_task_cell.not_last_day_of_week {
  border-right: 1px solid rgba($bmNeutral4, .40);
}

.gantt_container .gantt_task_cell.last_day_of_week {
  border-right: 1px solid $bmNeutral7 !important;
}

.dark {
  $ganttBorderDm: $bmNeutral8;
  .gridHoverStyle,
  .gridSelection,
  .timelineSelection {
    background-color: #42425e;
  }

  gantt_grid_scale .gantt_grid_head_cell {
    color: #c1c1c1;
  }

  .gantt_grid_data .gantt_cell {
    color: var(--ion-text-color);
  }

  .chartHeaderBg {
    background-color: #000;
  }

  .gantt_task .gantt_task_scale .gantt_scale_cell {
    color: #c1c1c1;
    border-right-color: #7e7e7e;
  }

  .gantt_row.gantt_project,
  .gantt_row.odd.gantt_project {
    background-color: #edffee;
  }

  .gantt_task_row.gantt_project,
  .gantt_task_row.odd.gantt_project {
    background-color: #f5fff5;
  }

  .gantt_task_line.gantt_project {
    background-color: #006803;
    border-color: #006803;
  }

  .gantt_task_line.gantt_project .gantt_task_progress {
    background-color: #003b01;
  }

  .gantt_popup_shadow,
  .modalBorder {
    border-color: var(--ion-text-color);
    box-shadow: inset 0 0 0 4px #0505ff;
    padding: 4px;
  }

  .buttonBg {
    background: var(--ion-background-color);
  }

  .gantt_cal_larea input,
  .gantt_cal_larea select,
  .gantt_cal_larea textarea {
    background: var(--ion-background-color);
    color: var(--ion-text-color);
  }

  .gantt_cal_larea .gantt_section_time {
    background: var(--ion-background-color);
  }

  .gantt_cal_larea .gantt_cal_lsection {
    color: #c1c1c1;
  }

  .gantt_btn_set.gantt_cancel_btn_set {
    border: 2px solid #fff;
    background: var(--ion-background-color);
    color: var(--ion-text-color);
  }

  .gantt_btn_set.gantt_save_btn_set {
    border: 2px solid #76ff03;
    background: var(--ion-background-color);
    color: #76ff03;
  }

  .gantt_btn_set.gantt_delete_btn_set {
    border: 2px solid #ffc400;
    background: var(--ion-background-color);
    color: #ffc400;
  }

  .gantt_popup_button.gantt_ok_button {
    font-weight: 700;
    border: 2px solid #76ff03;
    background: var(--ion-background-color);
    color: #76ff03;
  }

  .gantt_popup_button.gantt_cancel_button {
    font-weight: 700;
    color: #454544;
    border: 2px solid #fff;
    background: var(--ion-background-color);
    color: var(--ion-text-color);
  }

  .gantt_popup_title {
    color: var(--ion-text-color);
  }

  .gantt_qi_big_icon,
  .gantt_qi_big_icon.icon_edit {
    border: 2px solid #fff;
    color: var(--ion-text-color);
  }

  .gantt_qi_big_icon.icon_edit {
    background: var(--ion-background-color);
  }

  .gantt_qi_big_icon.icon_delete {
    border: 2px solid #ffc400;
    background: var(--ion-background-color);
    color: #ffc400;
  }

  .gantt_tooltip {
    color: var(--ion-text-color);
    background: var(--ion-background-color);
  }

  .gantt_container,
  .gantt_tooltip {
    font-size: 14px;
    border: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_container {
    background-color: var(--ion-background-color);
  }

  .gantt_grid_scale,
  .gantt_task_scale {
    color: var(--ion-text-color);
    font-size: 14px;
    border-bottom: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_grid_scale,
  .gantt_task_scale,
  .gantt_task_vscroll {
    background-color: var(--ion-background-color);
  }

  .gantt_scale_line {
    border-top: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_grid_head_cell {
    border-right: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_grid_data .gantt_row.odd:hover,
  .gantt_grid_data .gantt_row:hover {
    background-color: #42425e;
  }

  .gantt_row,
  .gantt_task_row {
    border-bottom: 1px solid #7e7e7e;
    background-color: var(--ion-background-color);
  }

  .gantt_row.odd,
  .gantt_task_row.odd {
    background-color: var(--ion-background-color);
  }

  .gantt_grid_column_resize_wrap .gantt_grid_column_resize {
    background-color: var(--ion-color-text-secondary-shade);
  }

  .gantt_task_grid_row_resize_wrap .gantt_task_grid_row_resize {
    background-color: #7e7e7e;
  }

  .gantt_drag_marker.gantt_grid_resize_area,
  .gantt_drag_marker.gantt_row_grid_resize_area {
    background-color: hsla(0, 0%, 91%, 0.5);
  }

  .gantt_drag_marker.gantt_grid_resize_area {
    border-left: 1px solid var(--ion-color-text-secondary-shade);
    border-right: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_drag_marker.gantt_row_grid_resize_area {
    border-top: 1px solid var(--ion-color-text-secondary-shade);
    border-bottom: 1px solid var(--ion-color-text-secondary-shade);
    pointer-events: none;
  }

  .gantt_cell {
    border-right: 1px solid $ganttBorderDm;
  }

  .gantt_scale_cell {
    border-right: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_task_cell {
    border-right: 1px solid $ganttBorderDm;
  }

  .gantt_grid_data .gantt_last_cell, .gantt_grid_scale .gantt_last_cell, .gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell, .gantt_task_bg .gantt_last_cell {
    border-right-width: 0;
  }

  .gantt_task_cell.week_end {
    background-color: #4c4c4c;
  }

  .gantt_task_cell.day_off {
    background-color: rgba($bmTextDanger, .80);
  }

  .gantt_container .gantt_task_cell.not_last_day_of_week {
    border-right: 1px solid rgba($bmNeutral7, .20);
  }

  .gantt_container .gantt_task_cell.last_day_of_week {
    border-right: 1px solid $bmNeutral6 !important;
  }

  .gantt_tree_icon.gantt_open {
    width: 18px;
    cursor: pointer;
  }

  .gantt_tree_icon.gantt_close {
    width: 18px;
    cursor: pointer;
  }

  .gantt_invalid {
    background-color: #ffe0e0;
  }

  .gantt_error {
    color: var(--ion-color-danger);
  }

  .gantt_status {
    background: hsla(0, 0%, 61%, 0.1);
  }

  .gantt_column_drag_marker {
    border: 1px solid #cecece;
    opacity: 0.8;
  }

  .gantt_grid_head_cell_dragged {
    border: 1px solid #cecece;
    opacity: 0.3;
  }

  .gantt_grid_target_marker {
    background-color: #18ffff;
  }

  .gantt_grid_target_marker:before {
    border-top-color: #18ffff;
  }

  .gantt_grid_target_marker:after {
    border-bottom-color: #18ffff;
  }

  .gantt_modal_box {
    border-color: var(--ion-text-color);
    box-shadow: inset 0 0 0 4px #0505ff;
    border: 1px solid var(--ion-color-text-secondary-shade);
    background: var(--ion-background-color);
  }

  .gantt_button,
  .gantt_popup_button {
    border: 1px solid var(--ion-color-text-secondary-shade);
    background: var(--ion-background-color);
  }

  div.dhx_modal_cover {
    background-color: #000;
  }

  .gantt-alert-error,
  .gantt-confirm-error {
    border: 1px solid var(--ion-color-danger);
  }

  .gantt_popup_title {
    border-bottom: 1px solid #c1c1c1;
  }

  .gantt_popup_text {
    color: var(--ion-text-color);
  }

  .gantt-error,
  .gantt-info {
    color: #000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    background-color: var(--ion-text-color);
    border: 1px solid #fff;
  }

  .gantt-info div {
    background-color: var(--ion-text-color);
    border: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt-error {
    background-color: var(--ion-color-danger);
    border: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt-error div {
    background-color: var(--ion-color-danger);
    color: var(--ion-text-color);
  }

  .gantt-warning {
    background-color: #ef7f00;
    border: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt-warning div {
    background-color: #ef7f00;
    border: 1px solid transparent;
    color: var(--ion-text-color);
  }

  .gantt_data_area div,
  .gantt_grid div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .gantt_task_content {
    color: var(--ion-text-color);
  }

  .gantt_task_progress {
    background: #0e2d7d;
  }

  .gantt_task_line {
    background-color: #0042e9;
    border: 1px solid #0042e9;
  }

  .gantt_side_content {
    color: var(--ion-text-color);
  }

  .gantt_line_wrapper div {
    background-color: #18ffff;
  }

  .gantt_task_link:hover .gantt_line_wrapper div {
    box-shadow: 0 0 5px 0 #18ffff;
  }

  .gantt_link_control div {
    border: 1px solid #929292;
    background-color: #f0f0f0;
  }

  .gantt_link_control div:hover {
    background-color: var(--ion-text-color);
  }

  .gantt_link_source,
  .gantt_link_target {
    box-shadow: 0 0 3px #0042e9;
  }

  .gantt_link_target.link_finish_allow,
  .gantt_link_target.link_start_allow {
    box-shadow: 0 0 3px #65ffff;
  }

  .gantt_link_target.link_finish_deny,
  .gantt_link_target.link_start_deny {
    box-shadow: 0 0 3px #e87e7b;
  }

  .link_finish_allow .gantt_link_control.task_end_date div,
  .link_start_allow .gantt_link_control.task_start_date div {
    background-color: #65ffff;
    border-color: #18ffff;
  }

  .link_finish_deny .gantt_link_control.task_end_date div,
  .link_start_deny .gantt_link_control.task_start_date div {
    background-color: var(--ion-color-danger-tint);
    border-color: var(--ion-color-danger);
  }

  .gantt_link_arrow_right {
    border-left-color: #18ffff;
  }

  .gantt_link_arrow_left {
    border-right-color: #18ffff;
  }

  .gantt_link_arrow_up {
    border-color: transparent transparent #18ffff;
    border-bottom-color: #18ffff;
  }

  .gantt_link_arrow_down {
    border-top-color: #18ffff;
  }

  .gantt_task_progress_drag {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDQ2MjFDRjU2ODc4MTFFNjk2MEVEQjgxRDU3RThDRjQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDQ2MjFDRjY2ODc4MTFFNjk2MEVEQjgxRDU3RThDRjQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENDYyMUNGMzY4NzgxMUU2OTYwRURCODFENTdFOENGNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENDYyMUNGNDY4NzgxMUU2OTYwRURCODFENTdFOENGNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkyX4qoAAADrSURBVHjaYiz7z8yADXQx/mUCUr1QbjFQ3T9s6phwaOYEUqvkHBkLQBjIXg0VI2wAUKEIkNqrFc0YHLqDiQGEtWMYg0BiUDncBgAVqACpY5bVjJY+i5kYmNkYwNh7ERMDSAwkB1WDaQBQwoKJheGY+ywmVdsWoDAjkiogGyTmMZtJFajmOEgtigFAgSA2HoZ9wZuZRPVTGRlwAb0URgagGhGQWpAeqNkM+TySDL3BW5mYxQ1xa0YGL8//Z1jr8+/fl2cMRSAd/zMeMjPwyTGQBD49YmCYIf8X4gVSNSPrYWKgEIC9QIkBAAEGAKWdQQSo7CbHAAAAAElFTkSuQmCC);
  }

  .gantt_task_progress_drag:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVQoz6WPP0tCURiHn9+5d7huEd5VKhIEwaEQlOtQS0HjbXBy6ANUkuEo4njAz9Bee5tu2bfoCwTRVsu5p6E7GHoJ8dneP7+H99XQB6zDyhlgmpeDoQ+ydXumIFwCHuOG+nFDfeAp7/0vsHJlYFY50WVnIjoTUTlVCszyWbHAyh0Ci1pX7ea9MCGYEJoDUeuqDSzynVWBlWspYHF0rWq9p5VT6z1xfKOqAl6tXOuPwMqlYcQ8GSnePxdF7J2JZKRyGDG3cimAgNtol2kyVrBzUBxe5vPN8zL22fcHdwL8xYOhFLMRX+/wfJX9vrBpeDlj2BIBfhvBD+rwPMTN8+ghAAAAAElFTkSuQmCC);
  }

  .gantt_link_tooltip {
    box-shadow: 3px 3px 3px #888;
    background-color: var(--ion-text-color);
    border-left: 1px dotted #cecece;
    border-top: 1px dotted #cecece;
    color: #444;
  }

  .gantt_link_direction {
    border: 0 none #18ffff;
  }

  .gantt_grid_data .gantt_row.gantt_selected,
  .gantt_grid_data .gantt_row.odd.gantt_selected,
  .gantt_task_row.gantt_selected {
    background-color: #42425e;
  }

  .gantt_task_row.gantt_selected .gantt_task_cell {
    border-right-color: #2d2d40;
  }

  .gantt_task_line.gantt_selected {
    box-shadow: 0 0 5px #0e2d7d;
  }

  .gantt_task_line.gantt_project.gantt_selected {
    box-shadow: 0 0 5px #006803;
  }

  .gantt_task_line.gantt_milestone {
    background-color: #c06fce;
    border: 0 solid #ef8eff;
  }

  .gantt_task_line.gantt_task_inline_color {
    border-color: #999;
  }

  .gantt_task_line.gantt_task_inline_color .gantt_task_progress {
    background-color: #363636;
  }

  .gantt_task_line.gantt_task_inline_color.gantt_project.gantt_selected,
  .gantt_task_line.gantt_task_inline_color.gantt_selected {
    box-shadow: 0 0 5px #999;
  }

  .gantt_task_link.gantt_link_inline_color:hover .gantt_line_wrapper div {
    box-shadow: 0 0 5px 0 #999;
  }

  .gantt_critical_task {
    background-color: var(--ion-color-danger);
    border-color: var(--ion-color-danger-shade);
  }

  .gantt_critical_task .gantt_task_progress {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .gantt_critical_link .gantt_line_wrapper > div {
    background-color: var(--ion-color-danger);
  }

  .gantt_critical_link .gantt_link_arrow {
    border-color: var(--ion-color-danger);
  }

  .gantt_btn_set:focus,
  .gantt_cell:focus,
  .gantt_grid_head_cell:focus,
  .gantt_popup_button:focus,
  .gantt_qi_big_icon:focus,
  .gantt_row:focus {
    box-shadow: inset 0 0 1px 1px #4d90fe;
  }

  .gantt_cal_light {
    border: 1px solid #c1c1c1;
    color: var(--ion-text-color);
    border-color: var(--ion-text-color);
    box-shadow: inset 0 0 0 4px #0505ff;
  }

  .gantt_cal_light select {
    border: 1px solid #c1c1c1;
  }

  .gantt_cal_lsection {
    color: #727272;
  }

  .gantt_cal_ltext textarea {
    border: 1px solid #c1c1c1;
  }

  .gantt_cal_larea {
    border: 1px solid #c1c1c1;
    background-color: #000;
  }

  .gantt_btn_set {
    border: 2px solid #c1c1c1;
    background: var(--ion-background-color);
  }

  .gantt_save_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEEyMTFDOUM2ODc5MTFFNjlDOTRDREYyNUQ4NENENTEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEEyMTFDOUQ2ODc5MTFFNjlDOTRDREYyNUQ4NENENTEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowQTIxMUM5QTY4NzkxMUU2OUM5NENERjI1RDg0Q0Q1MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowQTIxMUM5QjY4NzkxMUU2OUM5NENERjI1RDg0Q0Q1MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhVZXooAAAD2SURBVHjaxNO/a8JAFMBxUx1KQXAQcWj9sdhBCDg52I6FgjgIIi7duunsIl0dxNmxW3EUHaS0tFj1DxJRKiX1/AZeIYTGNMnQg8927727e++0tgqHgqyTUMDlN8E55tAjPoIzeEcWPa8nyEllM3iJupcEeXzgAm+4xdpMoGOB9JHgAmZIYooKtj+P2MWVHO3yl+Ci3DmOEar4tHahIRtSkqRgCb7GK2IYmnfGl72NG5QxRkKSlXCDZ0TxiDsYTnOwQw1PUu0FE5xhgHt8uw2SIVUGEniKPlpQTq9rHyRzYxMr7PHg1lunSez8dTg0pdT//saDAAMAj/Mt6qJaGUkAAAAASUVORK5CYII=);
  }

  .gantt_cancel_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MERGODM2RkI2ODc5MTFFNkFCOTRCOTNEMkMwODJCQkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MERGODM2RkM2ODc5MTFFNkFCOTRCOTNEMkMwODJCQkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowREY4MzZGOTY4NzkxMUU2QUI5NEI5M0QyQzA4MkJCRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowREY4MzZGQTY4NzkxMUU2QUI5NEI5M0QyQzA4MkJCRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ppyd3J4AAAFPSURBVHjajJO5SkNRFEVfnlEbU9qrjVMwYiMBEbSK4g8IllFQK/EvLCwEEYdS/AOHIiJYWinEqREtbLQKCGpQcW3YgUuI8R1YvOTdvU9uzpCqVCpRXQxAEaag2+8e4AT24CYUp4IEbbAOixBHjePHSVbhLQqEMh/CMnzBBuQhY/J+p7MFOIaO8AabsATPMAOXDX69E86hz9+35FGCQT5cwTeMNjGfgbT30AVpyMUuWAtsJzBfwzjs2FNUgoKFBwnMk/ASaAv6C58uYjtUE5gjaz+kT//RrnrzBLwG561+VmMPiWIkoVmR9fMp9oQpZhOaa1pFSTXIuvoakkfo/cc8BBdu47BuUIZdF0bmuybmHBxZq7aXa5OY8cGYO6Ep24dbG/thznuijp3CtLThMinJmme92TLptivwXr+NtVAB5z1gPb6RalPyJpZD8a8AAwBoDGzHPr0K4wAAAABJRU5ErkJggg==);
  }

  .gantt_delete_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTZBMjlFNkU2ODc5MTFFNjkxOTRGRTZGMDkwMENCMUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTZBMjlFNkY2ODc5MTFFNjkxOTRGRTZGMDkwMENCMUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxNkEyOUU2QzY4NzkxMUU2OTE5NEZFNkYwOTAwQ0IxQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxNkEyOUU2RDY4NzkxMUU2OTE5NEZFNkYwOTAwQ0IxQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpeemjIAAABMSURBVHjaYvx/hAEbqAfiBjQxEL8RXSETkZphBtSjCzJCXfCfgTzAyMRAIWCBmUSpATBArFcY8QUiSWDUgFEDsBlwhAg9R5E5AAEGAFNACzFr4MENAAAAAElFTkSuQmCC);
  }

  .gantt_custom_button {
    border: 1px solid #cecece;
  }

  .gantt_cal_light_wide .gantt_cal_larea {
    border: 1px solid #c1c1c1;
  }

  .gantt_cal_light_wide .gantt_wrap_section {
    border-bottom: 1px solid #7e7e7e;
  }

  .gantt_section_time {
    background-color: var(--ion-text-color);
  }

  .gantt_section_time .gantt_time_selects select {
    border: 1px solid #c1c1c1;
  }

  .gantt_duration .gantt_duration_dec,
  .gantt_duration .gantt_duration_inc,
  .gantt_duration .gantt_duration_value {
    border: 1px solid #c1c1c1;
  }

  .gantt_resource_row {
    border-bottom: 1px solid #7e7e7e;
  }

  .gantt_cal_quick_info {
    border: 1px solid var(--ion-color-text-secondary-shade);
    border-color: var(--ion-text-color);
    box-shadow: inset 0 0 0 4px #0505ff;
    background-color: #000;
  }

  .gantt_cal_qi_title {
    color: var(--ion-text-color);
    background-color: #000;
    border-bottom: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_cal_qi_content {
    color: var(--ion-text-color);
  }

  .gantt_qi_big_icon {
    background: var(--ion-background-color);
    border: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_resource_marker {
    color: var(--ion-text-color);
  }

  .gantt_resource_marker_ok {
    background: rgba(78, 208, 134, 0.75);
  }

  .gantt_resource_marker_overtime {
    background: hsla(0, 100%, 76%, 0.69);
  }

  .gantt_histogram_fill {
    background-color: rgba(41, 157, 180, 0.2);
  }

  .gantt_histogram_hor_bar,
  .gantt_histogram_vert_bar {
    background: #299db4;
  }

  .gantt_histogram_cell {
    color: #000;
  }

  .gantt_marker {
    background-color: rgba(255, 0, 0, 0.4);
  }

  .gantt_marker .gantt_marker_content {
    color: var(--ion-text-color);
  }

  .gantt_grid_dnd_marker_line {
    background-color: #3498db;
  }

  .gantt_grid_dnd_marker_line:before {
    background: #fff;
    border: 3px solid #3498db;
  }

  .gantt_grid_dnd_marker_folder {
    box-shadow: inset 0 0 0 2px #3f98db;
  }

  .gantt_click_drag_rect {
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #3f98db;
    background-color: rgba(52, 152, 219, 0.3);
  }

  .gantt_click_drag_rect {
    outline: 1px solid #3f98db;
    background-color: rgba(52, 152, 219, 0.3);
  }

  .gantt_layout_cell > .gantt_layout_header {
    background: #33aae8;
    color: var(--ion-text-color);
  }

  .gantt_layout_header.collapsed_x {
    background: #a9a9a9;
  }

  .gantt_layout_header.collapsed_y {
    background: #a9a9a9;
  }

  .gantt_resizing {
    background: #f2f2f2;
  }

  .gantt_resizer_stick {
    background: #33aae8;
  }

  .gantt_resizer_error {
    background: var(--ion-color-danger-tint) !important;
  }

  .gantt_layout_cell_border_left {
    border-left: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_layout_cell_border_right:not(.gantt_resizer) {
    border-right: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_layout_cell_border_top {
    border-top: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_layout_cell_border_bottom {
    border-bottom: 1px solid var(--ion-color-text-secondary-shade);
  }

  .gantt_layout_cell_border_transparent {
    border-color: transparent;
  }

  .gantt_window {
    background: #fff;
  }

  .gantt_window_content_header {
    background: #39c;
    color: var(--ion-text-color);
    border-bottom: 2px solid #fff;
  }

  .gantt_window_content_frame {
    background: rgba(0, 0, 0, 0.1);
  }

  .gantt_window_modal {
    background: rgba(0, 0, 0, 0.1);
  }

  .gantt_drag_marker,
  .gantt_drag_marker .gantt_row.odd {
    background-color: #000;
  }

  .gantt_drag_marker .gantt_row {
    border-left: 1px solid #656565;
    border-top: 1px solid #656565;
  }

  .gantt_drag_marker .gantt_cell {
    border-color: #656565;
  }

  .gantt_row.gantt_over,
  .gantt_task_row.gantt_over {
    background-color: #0070fe;
  }

  .gantt_task_row.gantt_transparent {
    background-color: #d0ddff;
  }

  .gantt_popup_button.gantt_delete_button {
    border: 2px solid #76ff03;
    background: var(--ion-background-color);
    color: #76ff03;
  }

  /*
  .ganttContainer .gantt_container {
    background-color: var(--ion-background-color);
    color: var(--ion-text-color);
    .gantt_grid_scale {
      background-color: var(--ion-background-color);
      color: var(--ion-text-color);
    }
    .gantt_grid_data {
      background-color: var(--ion-background-color);
      color: var(--ion-text-color);
    }
  }
  */
}

.gantt_task_link.linkWithLag {
  & .gantt_line_wrapper div {
    background-color: #{$bmRed};
  }

  & .gantt_link_arrow_right {
    border-left-color: #{$bmRed};
  }
}

.gantt_task_link.linkWithoutLag {
  & .gantt_line_wrapper div {
    background-color: #{$bmPrimary};
  }

  & .gantt_link_arrow_right {
    border-left-color: #{$bmPrimary};
  }
}

/* If you change that also change it in gantt-shared.ts - GANTT_CUSTOM_LAYOUT_CSS (used for printing) */
.taskTextOverdue {
  padding-right: 20px; /* If you change this, also change in construction-schedule.page.ts - function getTaskFitValue */
  background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' data-prefix='fal' data-icon='exclamation-triangle' class='svg-inline--fa fa-exclamation-triangle fa-w-18' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23EFB003' d='M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right;
}

.gantt_container {
  .gantt_grid_scale .gantt_grid_head_cell {
    color: var(--ion-color-text-secondary);
    @include lightOrDarkGlobal(border-right-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_task .gantt_task_scale .gantt_scale_cell {
    color: var(--ion-color-text-secondary);
    @include lightOrDarkGlobal(border-right-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_grid_data .gantt_cell {
    color: var(--ion-text-color);
  }

  .gantt_side_content {
    @include lightOrDarkGlobal(color, $bmNeutral7, $bmNeutral4);
  }

  .gantt_task_cell {
    @include lightOrDarkGlobal(border-right-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_row, .gantt_task_row {
    @include lightOrDarkGlobal(border-bottom-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_layout_cell_border_right:not(.gantt_resizer) {
    @include lightOrDarkGlobal(border-right-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_grid_scale, .gantt_task_scale {
    @include lightOrDarkGlobal(border-bottom-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_grid_column_resize_wrap .gantt_grid_column_resize {
    @include lightOrDarkGlobal(background-color, $ganttBorderLm, $ganttBorderDm);
  }

  .gantt_scale_line {
    @include lightOrDarkGlobal(border-top-color, $ganttBorderLm, $ganttBorderDm);
  }
}

html:has(.gantt-drag-not-possible) .gantt_grid_dnd_marker {
  .gantt_grid_dnd_marker_line {
    background-color: #{$bmRed};
  }
  .gantt_grid_dnd_marker_line:before {
    border-color: #{$bmRed};
  }
  .gantt_grid_dnd_marker_folder {
    box-shadow: inset 0 0 0 2px #{$bmRed};
  }
}


