@import "./variables";

@each $key, $breakpoint in $breakpoints {
    @if ($breakpoint != 0px) {
        @media (max-width: $breakpoint - 1px) {
            html.plt-cordova .bau-hide-native-#{$key}-down {
                display: none;
            }
            html:not(.plt-cordova) .bau-hide-web-#{$key}-down {
                display: none;
            }
        }
    }
    @media (min-width: $breakpoint) {
        html.plt-cordova .bau-hide-native-#{$key}-up {
            display: none;
        }
        html:not(.plt-cordova) .bau-hide-web-#{$key}-up {
            display: none;
        }
    }
}
