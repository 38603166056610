@import "./color";

.app-split-pane {
  inset: 0px;
  display: flex;
  flex-flow: row nowrap;
  contain: strict;
  height: 100%;

  --split-pane-min-width: 270px;
  --split-pane-max-width: 350px;
  --split-pane-width: auto;

  .app-split-pane-side {
    height: 100%;
    min-width: var(--split-pane-min-width, 270px);
    max-width: var(--split-pane-max-width, 350px);
    width: var(--split-pane-width, auto);
    border-right: 1px solid var(--ion-color-omg-border);
  }

  .app-split-pane-main {
    height: 100%;
    flex-grow: 1;
  }
}

ion-split-pane.split-pane-visible {
  > ion-content:first-child {
    max-width: var(--side-max-width); // This property got lost with Ionic 8 upgrade
    min-width: var(--side-min-width); // This property got lost with Ionic 8 upgrade
  }
}
