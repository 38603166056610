@use "./new-ui";
@use "./mixins";
@use "./checkbox";
@use "./radio";
@use "./variables";
@use "../mixins" as old-mixins;
@use "../breakpoint";
@use "../color";

@mixin omg-form-item {
  --padding-start: #{mixins.spacing(0.25)} !important;
  --padding-end: #{mixins.spacing(0.25)} !important;
  --min-height: 48px;
  --highlight-background: #{color.$bmNeutral12};
  --full-highlight-height: 0;
  --border-color: #{color.$bmNeutral5};
  --transition: opacity 15ms linear, background-color 160ms ease 0s;
  --background: transparent;
  --color: #{color.$bmNeutral12};

  body.dark & {
    --highlight-background: #{color.$bmNeutral5};
    --border-color: #{color.$bmNeutral7};
    --color: #{color.$bmNeutral1};

    // Legacy input
    &.item-has-focus {
      --background: var(--omg-form-item-focus-background, #{color.$bmNeutral8});
    }

    // Ionic 7 input
    &:has(.has-focus) {
      --background: var(--omg-form-item-focus-background, #{color.$bmNeutral8});
    }

    ion-label {
      --color: #{color.$bmNeutral4};
    }
  }

  &.ion-invalid:not(.omg-form-invalid-after-touched),
  &.ion-invalid.ion-touched.omg-form-invalid-after-touched {
    --highlight-background: #{color.$bmRed};
    --border-color: #{color.$bmRed};
  }

  ion-label {
    --color: #{color.$bmNeutral7};

    &.omg-required::after {
      content: "*";
      color: color.$bmRed;
    }
  }

  // Ionic 7 - required label
  ion-input.omg-required label .label-text::after, ion-textarea.omg-required label .label-text::after {
    content: "*";
    color: color.$bmRed;
  }

  &.item-has-focus ion-label:not(.ion-color) {
    &.label-floating,
    &.label-stacked {
      color: var(--color);
    }
  }

  ion-label[position=floating],
  ion-label[position=stacked]
  {
    & + .input-container {
      width: 100%;
    }

    & + ion-textarea {
      --padding-top: 0;
      --padding-bottom: 0;
      margin-top: 0;
    }

    & + ion-input,
    & + .input-container > * {
      transform: translateY(-25%);
      --padding-top: 0;
      --padding-bottom: 0;
    }

    & + ionic-selectable,
    & + app-selectable > ionic-selectable,
    & + .input-container > ionic-selectable {
      transform: translateY(-25%);
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  // legacy input
  &.item-has-value,
  &.item-has-focus {
    ion-label[position=floating] {
      transform: scale(0.75);
    }
  }

  // Ionic 7 Input - move the floating label a bit further up to match the legacy layout
  ion-input[label-placement=floating], ion-textarea[label-placement=floating]
  {
    &.has-value, &.has-focus {
      label .label-text-wrapper {
        transform: translateY(15%) scale(0.75);
      }
    }
  }

  & ion-label[position=stacked] {
    transform: scale(0.75);
  }

  // legacy input
  &:not(.item-has-value):not(.item-has-focus) ion-label[position=floating] {
    transform: translateY(75%);
  }

  // legacy input
  &.item-has-focus {
    --background: var(--omg-form-item-focus-background, #{color.$bmNeutral2});
  }

  // Ionic 7 input
  &:has(.has-focus) {
    --background: var(--omg-form-item-focus-background, #{color.$bmNeutral2});
  }

  ionic-selectable .ionic-selectable-value {
    @include old-mixins.one-line-ellipsis;
  }

  &.mce-item {
    .tox.tox-tinymce {
      padding: 0;
    }

    editor.textEditor {
      padding-bottom: 0;
    }

    .textEditorToolbarContainer {
      padding-top: 0;
      padding-right: mixins.spacing(0.5);
    }
  }
}

@mixin omg-form-item-checkbox {
  --min-height: auto;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --highlight-background: transparent;
  --ripple-color: transparent;
  --background-hover-opacity: 0;

  &, body.dark & {
    --border-color: transparent;
  }

  ion-label {
    --color: #{color.$bmNeutral12};
    margin: 0;
    line-height: 1.5;
    white-space: break-spaces;

    body.dark & {
      --color: #{color.$bmNeutral0};
    }
  }

  ion-checkbox {
    @include checkbox.omg-checkbox(false);
  }

  ion-radio {
    @include radio.omg-radio;
  }
}

@mixin omg-form-item-color-picker {
  --min-height: auto;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --highlight-background: transparent;
  --ripple-color: transparent;
  --background-hover-opacity: 0;

  &, body.dark & {
    --border-color: transparent;
  }

  ion-label {
    margin: 0;
  }
}

@mixin omg-form-checkbox-grid {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint.breakpoint-down(md) {
    > :nth-child(2n) {
      order: 1;
    }
  }

  @include breakpoint.breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}

@mixin omg-form {
  ion-item.item {
    line-height: 1.5;
    @include omg-form-item;
    @include new-ui.new-ui {
      @include omg-form-item;
    }
  }

  ion-item.item.omg-form-checkbox {
    line-height: 1.5;
    @include omg-form-item-checkbox;
    @include new-ui.new-ui {
      @include omg-form-item-checkbox;
    }
  }

  .omg-form-color-picker {
    line-height: 1.5;
    @include omg-form-item-color-picker;
    @include new-ui.new-ui {
      @include omg-form-item-color-picker;
    }
  }

  .omg-form-checkbox-grid {
    @include omg-form-checkbox-grid;
  }
}
