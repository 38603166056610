@use "./mixins";
@use "./variables";
@use "../mixins" as old-mixins;
@use "../color";
@import "./new-ui";

ion-popover {
  @include new-ui {
    @at-root &.omg-top-popover {
      &::part(content) {
        top: 0 !important;
      }
    }
    @at-root &.omg-popover-wider {
      --width: 400px;
    }
    @at-root &.omg-popover-actions {
      --min-width: 250px;
      --width: auto;
      &::part(content) {
        border-radius: variables.$omgBrandBorderRadiusBig;
      }
      ion-item {
        margin-left: spacing(1);
        margin-right: spacing(2);
        border-radius: variables.$omgBrandBorderRadiusSmall;
        --padding-start: 0 !important;
      }
      ion-item:hover {
        --background: #{color.$bmNeutral2};
        body.dark & {
          --background: #{color.$bmNeutral9};
        }
      }
    }
    @at-root &.omg-popover-info {
      --width: 350px;
    }
    @at-root &.omg-popover-list-no-lines {
      ion-list ion-item {
        --border-color: transparent;
      }
    }
    @at-root &.omg-popover-grey-item {
      ion-list ion-item:nth-child(1) {
        --color: var(--ion-color-text-secondary);
      }
    }
    @at-root &.omg-popover-list-condensed {
      ion-list ion-item {
        @include mixins.medium-font;
        --min-height: #{variables.$omgFontSizeMedium * variables.$omgLineHeight + variables.$omgBaseSpacing};
        .icon-label {
          padding-left: 0;
        }
      }
    }
    &::part(content) {
      border-radius: variables.$omgMediumBorderRadius;
      --box-shadow: #{variables.$omgIonicBoxShadow};
    }
    @at-root &.omg-popover-brand-border::part(content) {
      border-radius: variables.$omgBrandBorderRadiusBig;
    }

    @at-root &.omg-popover-info-border::part(content) {
      border-radius: variables.$omgBrandBorderRadiusNormal;
    }

    &.no-pointer-events {
      pointer-events: none;
    }

    &.auto-width {
      --width: auto;
    }
  }
}
