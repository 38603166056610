@use "sass:math";
@use "./ui/mixins";
@import "color";
@import "variables";

@mixin generate-spacing-util($name, $rule) {
  $steps: (
    mixins.spacing(0.25),
    mixins.spacing(0.5),
    mixins.spacing(0.75),
    mixins.spacing(1),
    mixins.spacing(1.25),
    mixins.spacing(1.5),
    mixins.spacing(2),
    mixins.spacing(2.5)
  );

  @for $i from 1 through length($steps) {
    $step: nth($steps, $i);

    .#{$name}-#{$i} {
      #{$rule}: #{$step};
    }
  }
}

html.ios,
html.md {
  --ion-default-font: 'Inter', 'Muli', sans-serif !important;
  --ion-font-family: 'Inter', 'Muli', sans-serif !important;
  font-family: 'Inter', 'Muli', sans-serif !important;
}

:root {
  --bau-status-size: #{$bauStatusSize};
}

.pos-rel {
  position: relative;
}

.width-auto {
  --width: auto;
}

.heading-1 {
  font-size: 17px !important;
  font-weight: 600;
}

.heading-2 {
  font-weight: 600;
}

.f-w-normal {
  font-weight: normal;
}

.d-flex {
  display: flex;
}

@include generate-spacing-util(gap, gap);
@include generate-spacing-util(row-gap, row-gap);
@include generate-spacing-util(col-gap, column-gap);
@include generate-spacing-util(pb, padding-bottom);
@include generate-spacing-util(pt, padding-top);
@include generate-spacing-util(pl, padding-left);
@include generate-spacing-util(pr, padding-right);
@include generate-spacing-util(p, padding);
@include generate-spacing-util(mt, margin-top);
@include generate-spacing-util(mb, margin-bottom);
@include generate-spacing-util(ml, margin-left);
@include generate-spacing-util(mr, margin-right);

ion-grid.grid-gap-1 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(0.25, 2))};
  margin: mixins.spacing(math.div(-0.25, 2));
  padding: 0;
}

ion-grid.grid-gap-2 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(0.5, 2))};
  margin: mixins.spacing(math.div(-0.5, 2));
  padding: 0;
}

ion-grid.grid-gap-3 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(0.75, 2))};
  margin: mixins.spacing(math.div(-0.75, 2));
  padding: 0;
}

ion-grid.grid-gap-4 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(1, 2))};
  margin: mixins.spacing(math.div(-1, 2));
  padding: 0;
}

ion-grid.grid-gap-5 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(1.25, 2))};
  margin: mixins.spacing(math.div(-1.25, 2));
  padding: 0;
}

ion-grid.grid-gap-6 {
  --ion-grid-column-padding: #{mixins.spacing(math.div(1.5, 2))};
  margin: mixins.spacing(math.div(-1.5, 2));
  padding: 0;
}

ion-grid.grid-gap-7 {
  --ion-grid-column-padding: #{mixins.spacing(2)};
  margin: mixins.spacing(-2);
  padding: 0;
}

.flex-column {
  flex-direction: column;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.justify-between {
  justify-content: space-between;
}

.h-100 {
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

ion-input.no-padding,
ion-textarea.no-padding,
ion-item.no-padding {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
}

.no-padding-start {
  padding-left: 0px !important;
  --padding-start: 0px !important;
}

.no-padding-end {
  padding-right: 0px !important;
  --padding-end: 0px !important;
}

.no-padding-top {
  padding-top: 0px;
}

.no-padding-bottom {
  padding-bottom: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.no-margin-end {
  margin-right: 0px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-5{
 margin-bottom: 5px;
}

.margin-bottom-10{
  margin-bottom: 15px;
}

.padding-5 {
  padding: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}
.size-xxs {
  font-size: 10px;
}

.size-xs {
  font-size: 12px;
}

.size-s {
  font-size: 13px;
}

.size-sm {
  font-size: 14px;
}

.size-m {
  font-size: 16px !important;
}

.size-l {
  font-size: 18px;
}

.size-xl {
  font-size: 20px;
}

.full-width {
  width: 100%;
}

.text-transform-none {
  text-transform: none !important;
}

.border-bottom {
  border-bottom: 1px solid var(--ion-color-dark-shade);
}

.border-secondary {
  border: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  border-radius: 5px;
}

.border-medium {
  border: 1px solid var(--ion-color-text-secondary);
  color: var(--ion-color-text-secondary);
  background: none;
  --background: none;
  border-radius: 5px;
  &.bottom {
    border-bottom: 1px solid var(--ion-color-text-secondary);
  }
}

.text-wrap-one-line {
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-wrap-two-lines {
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.selectable-text-wrap-one-line-rtl .ionic-selectable-value {
  direction: rtl;
  display: inline-flex;
  justify-content: flex-end;
}

.selectable-item-text-wrap-one-line-rtl {
  direction: rtl;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  white-space: nowrap;
  div {
    overflow-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//only for browsers, override autofill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: white;
  transition: background-color 5000s ease-in-out 0s;
}

.canvas-container {
  display: inline-block !important;
}

.cursor-pointer {
  cursor: pointer
}

.mbsc-fr-btn-e,
ion-button {
  text-transform: capitalize !important;
}

.under-construction {
  text-align: center;
  ion-icon {
    font-size: 50px !important;
  }
}

.color-picker .item-native{
  padding-left: 0px !important;
}

.status-icon {
  height: 1em;
}

.no-image-icon-container .svg-inline--fa {
  vertical-align: 0 !important;
}

.header-button-left {
  margin-left: 0px;
  padding-left: 15px;
}

.footer-button-right,
.header-button-right {
  margin-right: 0px;
  padding-right: 15px;
}

.width-100 {
  width: 100px;
}

.width-120 {
  width: 120px;
}

.width-150 {
  width: 150px;
}

ion-label.required:after {
  content: ' *';
  color: #{$bmTextDanger};
}

form:not(.omg-form) {
  ion-textarea {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  ion-col {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  ion-datetime {
    padding-bottom: 7px !important;
  }

  ion-item {
    --height: 40px !important;
  }

  ion-item > ion-label {
    font-size: 14px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 768px) {
  .bau-small-modal {
    --height: 300px;
  }
}

.z-index-99999 {
  z-index: 99999 !important;
}

.grey-text {
  color: var(--ion-color-text-secondary);
}

.no-pointer-events {
  pointer-events: none;
}

.no-user-events {
  user-select: none;
}

.white-space-pre-line {
  white-space: pre-line;
}

.button-wrapper {
  position: relative;
  width: fit-content;
}

.upselling-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 4px;
  top: 4px;
  pointer-events: none;
}
