@use "./mixins";

@mixin omg-header-1 {
  @include mixins.extra-extra-large-font;
}

@mixin omg-header-1-bold {
  @include omg-header-1;
  font-weight: 600;
}

@mixin omg-header-2 {
  @include mixins.extra-large-font;
}

@mixin omg-header-2-bold {
  @include omg-header-2;
  font-weight: 600;
}

@mixin omg-header-3 {
  @include mixins.large-font;
}

@mixin omg-header-3-bold {
  @include omg-header-3;
  font-weight: 600;
}

@mixin omg-body {
  @include mixins.normal-font;
}

@mixin omg-body-bold {
  @include omg-body;
  font-weight: 600;
}

@mixin omg-medium-body {
  @include mixins.medium-font;
}

@mixin omg-medium-body-bold {
  @include omg-medium-body;
  font-weight: 600;
}

@mixin omg-xs-body {
  @include mixins.small-font;
}

@mixin omg-xs-body-bold {
  @include omg-xs-body;
  font-weight: 600;
}

@mixin omg-info {
  @include mixins.info-font;
}

@mixin omg-info-bold {
  @include omg-info;
  font-weight: 600;
}

@mixin omg-hint {
  @include mixins.hint-font;
}

@mixin omg-hint-bold {
  @include omg-hint;
  font-weight: 700;
}
