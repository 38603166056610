@use "../color";

$omgBaseSpacing: 16px;

$omgFontSizeExtraExtraLarge: 24px;
$omgFontSizeExtraLarge: 20px;
$omgFontSizeLarge: 18px;
$omgFontSizeNormal: 16px;
$omgFontSizeMedium: 14px;
$omgFontSizeSmall: 12px;
$omgFontSizeInfo: 11px;
$omgFontSizeHint: 10px;

$omgLineHeight: 1.5;

$omgBoxShadow: 0 1px 2px rgba(0, 0, 0, 0.15);
$omgHeaderBoxShadow: 0px 0px 10px rgba(0, 0, 0, 0.1),
                     0px 2px 3px rgba(0, 0, 0, 0.15);
$omgIonicBoxShadow: 0px 0px 2px rgba(0, 0, 0, 0.14),
                    0px 2px 2px rgba(0, 0, 0, 0.12),
                    0px 1px 3px rgba(0, 0, 0, 0.2);
$omgActiveCardBoxShadow: 0px 0px 10px rgba(color.$bmNeutral12, 0.1),
                         0px 2px 3px rgba(color.$bmNeutral12, 0.15);
$omgActiveCardBoxShadowDarker: 0px 0px 10px rgba(color.$bmNeutral12, 0.5),
                               0px 2px 3px rgba(color.$bmNeutral12, 0.15);
$omgCardShadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
$omgSelectedCardShadow: 0px 3px 8px rgba(29, 37, 46, 0.12);
$omgIonCardShadow: 0 3px 8px rgba(color.$bmNeutral12, 0.12);

$omgBorderRadius: 4px;
$omgMediumBorderRadius: 8px;

$omgBrandBorderRadiusSmallest: 4px 1px 1px 1px;
$omgBrandBorderRadiusSmaller: 8px 2px 2px 2px;
$omgBrandBorderRadiusSmall: 12px 3px 3px 3px;
$omgBrandBorderRadiusNormal: 16px 4px 4px 4px;
$omgBrandBorderRadiusBig: 20px 5px 5px 5px;
$omgBrandBorderRadiusBigger: 24px 6px 6px 6px;
$omgBrandBorderRadiusHuge: 32px 8px 8px 8px;

$omgButtonHeight: 38px;
$omgFabButtonSize: 56px;
$omgButtonRadius: 12px 3px 3px 3px;
$omgFabButtonRadius: 20px 5px 5px 5px;
$omgNewEntryTagRadius: 0 0 2px 2px;
$omgButtonHoverShadow: 0 2px 3px rgba(color.$bmNeutral12, 0.1);
$omgButtonPaddingStart: 0.75 * $omgBaseSpacing;
$omgButtonPaddingEnd: 0.75 * $omgBaseSpacing;

$omgTransition: 160ms ease 0s;
