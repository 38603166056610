@use "./new-ui";
@use "./mixins";
@use "./variables";
@use "../color";

ion-card {
  @include new-ui.new-ui {
    --color: var(--ion-text-color);
    border-radius: #{$omgBrandBorderRadiusSmall};
    box-shadow: variables.$omgIonCardShadow;
    @include mixins.normal-font;

    body.dark & {
      --background: #{color.$bmNeutral9};
      border: none;
    }
  }
}
