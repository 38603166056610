@use './variables';
@use './mixins';
@use '../mixins' as old-mixins;
@use '../color';

@mixin omg-inline-input-box {
  background-color: var(--omg-inline-input-background-color);
  padding: 0 mixins.spacing(0.25);
  transition: background-color .2s ease;
  width: 100%;
  border-bottom: 1px solid var(--omg-inline-input-border-color);
  outline-color: var(--omg-inline-input-border-color);
}

@mixin input-clear {
  border: none;
}

@mixin omg-inline-input {
  --omg-inline-input-background-color: #{color.$bmNeutral2};
  --omg-inline-input-border-color: #{color.$bmNeutral5};
  &:focus {
    --omg-inline-input-border-color: #{color.$bmNeutral8};
  }
  &.ng-invalid {
    --omg-inline-input-background-color: rgba(var(--ion-color-danger-rgb), 0.15);
    --omg-inline-input-border-color: var(--ion-color-danger);
  }
  body.dark & {
    --omg-inline-input-background-color: #{color.$bmNeutral8};
    --omg-inline-input-border-color: #{color.$bmNeutral7};
    &:focus {
      --omg-inline-input-border-color: #{color.$bmNeutral4};
    }
    &.ng-invalid {
      --omg-inline-input-background-color: rgba(var(--ion-color-danger-rgb), 0.3);
      --omg-inline-input-border-color: var(--ion-color-danger);
    }
  }

  @include input-clear();
  @include omg-inline-input-box();
}
