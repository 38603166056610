@use "./variables";

@function spacing($size) {
  @return $size * 16px;
}

@mixin extra-extra-large-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeExtraExtraLarge;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin extra-large-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeExtraLarge;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin large-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeLarge;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin normal-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeNormal;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin medium-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeMedium;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin small-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeSmall;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin info-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeInfo;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin hint-font($lineHeight: variables.$omgLineHeight) {
  font-size: variables.$omgFontSizeHint;
  @if $lineHeight != "skip" {
    line-height: $lineHeight;
  }
}

@mixin active-elevation($cssVariable: false) {
  @if $cssVariable {
    --box-shadow: #{variables.$omgActiveCardBoxShadow};
  } @else {
    box-shadow: variables.$omgActiveCardBoxShadow;
  }
}

@mixin clear-button() {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  width: 100%;
  outline: none;
  background: inherit;
}
