@use "./mixins";
@use "./typography";
@use "../mixins" as old-mixins;
@use "../color";
@use "./variables";

@mixin omg-notification-badge {
  padding: 0 mixins.spacing(0.25);
  border-radius: variables.$omgBrandBorderRadiusSmallest;

  @include typography.omg-xs-body-bold;

  @include old-mixins.lightOrDarkGlobal(background, color.$bmRed, color.$bmBadgeRedBackgroundDm);
  color: color.$bmWhite;
}
