@use './mixins';
@use './variables';
@use './tokens/button';

@mixin omg-slim-searchbar {
  @at-root & {
    --background: transparent;
    border: 1px solid map-get(map-get(map-get(button.$btnDefault, 'lm'), 'secondary'), 'border-color-default');
    padding: 0;
    border-radius: variables.$omgButtonRadius;
    --box-shadow: none;
    --color: #{map-get(map-get(map-get(button.$btnDefault, 'lm'), 'secondary'), 'color-default')};
    --icon-color: #{map-get(map-get(map-get(button.$btnDefault, 'lm'), 'secondary'), 'color-default')};

    body.dark & {
      border-color: map-get(map-get(map-get(button.$btnDefault, 'dm'), 'secondary'), 'border-color-default');
      --color: #{map-get(map-get(map-get(button.$btnDefault, 'dm'), 'secondary'), 'color-default')};
      --icon-color: #{map-get(map-get(map-get(button.$btnDefault, 'dm'), 'secondary'), 'color-default')};
    }

    $iconWidth: 16px;

    .searchbar-search-icon {
        left: mixins.spacing(0.75);
        width: $iconWidth;
        height: $iconWidth;
        top: 10px;
    }

    .searchbar-input {
        padding-left: mixins.spacing(0.75) + $iconWidth + mixins.spacing(0.5);
        padding-right: mixins.spacing(0.75) + $iconWidth + mixins.spacing(0.5);
    }

    .searchbar-clear-button {
      right: mixins.spacing(0.75);
    }

    .searchbar-clear-icon {
        width: $iconWidth;
    }

    &.ios {
      border-radius: 10px;
    }
  }
}
