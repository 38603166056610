@use "./slim-searchbar";
@use "./checkbox";
@use "./form";
@use "./radio";
@use "../slim-searchbar" as old-slim-searchbar;
@use "../color";
@import "./variables";
@import "./mixins";
@import "../variables";

@mixin omg-modal {
  @at-root & {
    &.modal-default:not(.overlay-hidden) ~ &.modal-default {
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }

    &::part(content) {
      --border-radius: #{$omgBrandBorderRadiusHuge};
      --max-height: 90vh;

      @media (max-width: (map-get($breakpoints, "md") - 1px)) {
        --max-height: 100vh;
        
        & .ion-page {
          min-height: 100vh;
          max-height: 100vh;
        }
      }

      --ion-background-color: #{color.$bmModalBackgroundLm};
      --ion-item-background: #{color.$bmModalBackgroundLm};
      --ion-toolbar-background: #{color.$bmModalBackgroundLm};
      --background: #{color.$bmModalBackgroundLm};

      body.dark & {
        --ion-background-color: #{color.$bmModalBackgroundDm};
        --ion-item-background: #{color.$bmModalBackgroundDm};
        --ion-toolbar-background: #{color.$bmModalBackgroundDm};
        --background: #{color.$bmModalBackgroundDm};
      }
    }

    ionic-selectable-modal {
      ion-searchbar {
        @include old-slim-searchbar.bau-slim-searchbar;
      }
      ion-toolbar.toolbar-searchbar {
        --padding-start: #{spacing(1)};
        --padding-end: #{spacing(1)};
        --padding-top: 0;
        --padding-bottom: #{spacing(1)};
        --min-height: auto;

        ion-searchbar {
          @include slim-searchbar.omg-slim-searchbar;
        }

        ion-searchbar {
          border-radius: $omgButtonRadius;
          border-color: color.$bmNeutral5;
          body.dark & {
            border-color: color.$bmNeutral7;
          }
        }
      }
      &.ionic-selectable-modal .ionic-selectable-message {
        padding: 0;
      }
      ion-item.ionic-selectable-item {
        padding-left: 0px !important;
        padding-right: 0px !important;
        --padding-start: #{spacing(2)} !important;
        --padding-end: #{spacing(2)} !important;
        --border-color: transparent;

        &.item ion-label {
          margin: 0;
          max-height: var(--min-height);

          @supports (-webkit-line-clamp: 2) {
            overflow-wrap: anywhere;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
          }
        }

        ion-icon[slot=start] {
          @include checkbox.omg-checkbox-icon;
        }

        ion-checkbox {
          @include checkbox.omg-checkbox;
        }

        ion-radio {
          @include radio.omg-radio;
        }
      }
    }

    ion-header {
      box-shadow: $omgHeaderBoxShadow;

      ion-toolbar {
        --min-height: 64px;
        --padding-top: #{spacing(1)};
        --padding-bottom: #{spacing(1)};
        --padding-start: #{spacing(1)};
        --padding-end: #{spacing(1)};

        .omg-modal-list-header {
          padding-top: spacing(1);
          margin-bottom: spacing(-0.5);
        }
      }

      &.ios {
        padding-top: 0;
      }

      ion-title {
        padding: 0 40px; // 40px = ~close button width
        text-align: center;
        height: auto;
        position: static;

        color: color.$bmNeutral7;
        body.dark & {
          color: color.$bmNeutral4;
        }
      }

      app-step-indicator {
        width: 100%;
      }

      ion-buttons[slot="end"] {
        position: absolute;
        right: spacing(0.5);
        top: spacing(0.5);

        margin-right: 0;
        @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
          margin-right: unset;
          margin-inline-end: 0;
        }

        ion-button {
          margin: 0;
        }
      }
    }

    ion-content {
      --background: transparent;

      &.with-padding::part(scroll) {
        --modal-content-padding: #{spacing(1.5)};
        padding: spacing(1.5);

        @media (max-width: (map-get($breakpoints, "lg") - 1px)) {
          --modal-content-padding: #{spacing(1)};
          padding: spacing(1);
        }

        @media (max-width: (map-get($breakpoints, "sm") - 1px)) {
          --modal-content-padding: #{spacing(0.75)};
          padding: spacing(0.75);
        }
      }
    }

    ion-footer {
      &.footer-md {
        box-shadow: none;
      }

      ion-toolbar {
        --min-height: auto;

        ion-buttons.md:not(:empty) {
          padding: spacing(0.625) spacing(1.5) spacing(1.5);
          gap: spacing(0.75);

          &[slot=start] {
            margin-left: 0;
            @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
              margin-left: unset;
              margin-inline-start: 0;
            }
          }

          &[slot=end] {
            margin-right: 0;
            @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
              margin-right: unset;
              margin-inline-end: 0;
            }
          }

          @media (max-width: (map-get($breakpoints, "md") - 1px)) {
            padding: spacing(0.625) spacing(1) spacing(1);
          }

          @media (max-width: (map-get($breakpoints, "sm") - 1px)) {
            padding: spacing(0.625) spacing(0.75) spacing(0.75);
          }

          ion-button {
            height: $omgButtonHeight;
            margin: 0;
          }
        }

        ion-item.item.omg-form-checkbox {
          @include form.omg-form-item-checkbox;
          
          ion-label {
            --color: #{color.$bmNeutral7};

            body.dark & {
              --color: #{color.$bmNeutral7};
            }
          }
        }
      }
    }
  }
}

@mixin omg-modal-typography {
  .omg-modal-header {
    @include large-font;
    font-weight: bold;
    padding-top: spacing(0.5);
    color: $bmNeutral12;
    body.dark & {
      color: $bmNeutral1;
    }
  }

  .omg-modal-section-header {
    @include normal-font;
    font-weight: bold;
    color: $bmNeutral7;
    body.dark & {
      color: $bmNeutral4;
    }
  }

  .omg-modal-section-header-small {
    @include medium-font;
    font-weight: bold;
    color: $bmNeutral6;
    body.dark & {
      color: $bmNeutral5;
    }
  }
}
