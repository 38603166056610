@import "color";

.primary {
  color: $bmPrimary;
}

.heading-primary {
  color: var(--ion-text-color);
}

.text-primary {
  color: var(--ion-text-color);
}

.text-secondary {
  color: $bmTextSecondary;
}

.text-secondary-imp {
  color: $bmTextSecondary !important;
}

.text-grey {
  color: var(--ion-color-text-grey);
}

.danger {
  color: $bmTextDanger !important;
}

.success {
  color: $bmSuccess !important;
}

.warning {
  color: $bmWarning !important;
}

.error {
  color: $bmRed !important;
}

.inactive {
  color: $bmInactive;
}

.background-var {
  background-color: var(--background);
}
