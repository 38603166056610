@use "./color";
@import "./mixins";

.item-textarea:not(.item-has-value):not(.item-has-focus) .label-floating.sc-ion-label-md-h {
  transform: translateY(96%);
}

ion-input.cursor-pointer > .native-input {
  cursor: pointer !important;
}

// Ionic 7 - This is to change the color of the bottom line when highlighted
ion-item:not(.item-has-focus) {
  // This is to exclude legacy inputs (they have .item-has-focus when focused)
  &:has(ion-input.has-focus), &:has(ion-textarea.has-focus) {
    // only if the items have focused input areas
    --border-color: #{color.$bmNeutral12};

  }

  body.dark & {
    &:has(ion-input.has-focus), &:has(ion-textarea.has-focus) {
      --border-color: #{color.$bmNeutral5};
    }
  }
}

// Ionic 7 - change color of valid and invalid input fields and labels
ion-input, ion-textarea {

  &:not(.ion-invalid),
  &:not(.ion-touched) {
    --highlight-color-valid: #{color.$bmNeutral12};
    --highlight-color-focused: #{color.$bmNeutral12};
    --highlight-color: #{color.$bmNeutral12};

    // Label color when not invalid
    .label-text, .label-text-wrapper {
      color: #{color.$bmNeutral7};
    }

    body.dark & {
      --highlight-color-valid: #{color.$bmNeutral0};
      --highlight-color-focused: #{color.$bmNeutral0};
      --highlight-color: #{color.$bmNeutral0};

      // Label color (dark) when not invalid
      .label-text, .label-text-wrapper {
        color: #{color.$bmNeutral4};
      }
    }
  }

  &.ion-invalid.ion-touched {
    --highlight-color-valid: #{color.$bmRed3};
    --highlight-color-focused: #{color.$bmRed3};
    --highlight-color: #{color.$bmRed3};

    // Label color when invalid
    .label-text, .label-text-wrapper {
      color: #{color.$bmRed3};
    }

    body.dark & {
      --highlight-color-valid: #{color.$bmRed3};
      --highlight-color-focused: #{color.$bmRed3};
      --highlight-color: #{color.$bmRed3};

      // Label color (dark) when invalid
      .label-text, .label-text-wrapper {
        color: #{color.$bmRed3};
      }
    }
  }
}

// Override Ionic 7 default height of 56px
.input-label-placement-floating.sc-ion-input-md-h, .input-label-placement-stacked.sc-ion-input-md-h {
  min-height: 48px;
}

.tox.tox-tinymce {
  border: none
}

/* No border around tinymce editor */
.mce-content-body {
  outline: 0px solid !important;
  position: unset !important;

  & > :first-child {
    margin-top: 0;
  }
}

ion-item.mce-item {
  overflow: visible;

  &.item-has-focus > ion-label,
  &.item-has-value > ion-label {
    padding-bottom: 1.5px;
  }

  .tox.tox-tinymce {
    padding: spacing(1) 0;
  }

  .tox .tox-dialog-wrap {
    top: unset;
    bottom: unset;
  }

  &:focus, &:focus-within {
    z-index: 2;

    .textEditorToolbarContainer {
      overflow: visible;
    }
  }

  editor.textEditor {
    width: 100%;
    padding-top: 0;
    padding-bottom: 8px;
  }

  .textEditorToolbarContainer {
    padding-top: 5px;
    overflow: hidden;
  }
}

ion-input.legacy.sc-ion-input-md-h {
  min-height: 42px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 6px;
  --padding-end: 6px;

  .input-outline-container .input-outline-start {
    width: 4px;
  }

  --highlight-height: 1px;
}

ion-input.email-settings label div.label-text-wrapper div.label-text {
  font-size: 16px !important;
  margin-right: 8px !important;
  margin-right: 8px !important;
  font-weight: normal !important;
  color: var(--ion-color-text-secondary) !important;
}
