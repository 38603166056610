@import "./mixins";

$defaultDirection: row;
$defaultJustifyContent: flex-start;
$defaultAlignItems: center;
$defaultGap: spacing(1);

@mixin omg-layout {
  display: flex;

  flex-direction: $defaultDirection;
  justify-content: $defaultJustifyContent;
  align-items: $defaultAlignItems;
  gap: $defaultGap;
}

@mixin omg-layout-column {
  @include omg-layout();

  flex-direction: column;
  align-items: stretch;
}
