@use "./new-ui";
@use "./variables";
@use "../color";
@use "../mixins" as old-mixins;

ion-segment {
  @include new-ui.new-ui {
    display: flex;
    height: variables.$omgButtonHeight;
    --min-height: auto;
    border-radius: variables.$omgButtonRadius;
    border: 1px solid color.$bmNeutral5;

    body.dark & {
      border-color: color.$bmNeutral7;
    }

    ion-segment-button {
      letter-spacing: normal;
      text-transform: none;
      min-height: auto;
      flex: 1 1 0;
      max-width: unset;
      --indicator-height: 0px;
      --ripple-color: transparent;
      --background-hover-opacity: 0;
      --background-activated-opacity: 0;
      --color: #{color.$bmNeutral7};
      --color-hover: #{color.$bmNeutral12};
      --color-checked: #{color.$bmNeutral12};
      
      body.dark & {
        --color: #{color.$bmNeutral4};
        --color-hover: #{color.$bmNeutral0};
        --color-checked: #{color.$bmNeutral0};
      }
      border-radius: 0;

      ion-label {
        margin: 0;
      }
    }

    ion-segment-button.segment-button-checked {
      background: color.$bmNeutral4;
      body.dark & {
        background: color.$bmNeutral8;
      }
    }
  }
}
