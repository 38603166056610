@use 'sass:math';
@import "color";


ion-toggle.bau-slim-toggle {
    $toggle-size: 16px;
    $border-width: 0;
    $toggle-width-ratio: 2;
    $toggle-handle-inset: 2px;
    $border-handle-width: 0;

    $toggle-width: ($toggle-size - $border-width * 2) * $toggle-width-ratio;
    
    $toggle-background: $bmNeutral7;
    $toggle-handle-background: $bmWhite;

    $toggle-background--dark: $bmNeutral7;
    $toggle-handle-background--dark: $bmWhite;

    --track-background: #{$bmNeutral7};
    --handle-background-checked: var(--ion-success-color, #{$bmBrand6});
    --handle-width: #{($toggle-size - $border-handle-width * 2) - $toggle-handle-inset * 2};
    --handle-height: #{($toggle-size - $border-handle-width * 2) - $toggle-handle-inset * 2};
    --handle-border-radius: #{math.div($toggle-size, 2) - $toggle-handle-inset};
    --border-radius: #{math.div($toggle-size, 2)};
    --handle-box-shadow: none;
    --handle-spacing: 0px;
    padding: 0 6px;
    height: $toggle-size;
    width: $toggle-width;

    flex: unset;

    .dark & {
        &::part(track) {
            background: $toggle-background--dark;
        }
        &::part(handle) {
            background: $toggle-handle-background--dark;
        }
    }

    &::part(track) {
        background: $toggle-background;
        border: $border-width solid var(--background);
        height: $toggle-size - $border-width * 2;
        width: $toggle-width;

        will-change: border-color, background;
        transition: border-color 160ms ease 0s,
                    background 160ms ease 0s;
    }

    &.toggle-checked::part(track) {
        background: var(--handle-background-checked);
    }

    &::part(handle) {
        margin-left: -$border-width + $toggle-handle-inset;
        border: $border-handle-width solid var(--background);
        background: $toggle-handle-background;

        will-change: border-color background;
        transition: border-color 160ms ease 0s,
                    background 160ms ease 0s;
    }
    &.toggle-checked::part(handle) {
        margin-left: $border-width - $toggle-handle-inset;
    }
}
