@import '../app/scss/color';

// Functions to work as
// https://github.com/ionic-team/ionic-docs/blob/c4c9287880c96b03381cb1df52495ce5812ad0d2/src/components/page/theming/_utils/color.ts#L84
// does
@function tint($color, $weight: 10%) {
  @return mix(#ffffff, $color, $weight);
}

@function shade($color, $weight: 12%) {
  @return mix(#000000, $color, $weight);
}

// https://en.wikipedia.org/wiki/YIQ#From_RGB_to_YIQ
@function y_in_yiq($color) {
  $red: red($color);
  $green: green($color);
  $blue: blue($color);
  
  @return (($red*.299)+($green*.587)+($blue*.114));
}

@function contrast($color, $threshold: 128) {
  @if y_in_yiq($color) >= $threshold {
    @return #000000;
  } @else {
    @return #ffffff;
  }
}

@function rgb_str($color) {
  @return #{red($color)}, #{green($color)}, #{blue($color)};
}

@mixin generate-ionic-color-detailed($colorName, $colorDef, $darkColorDef: "") {
  $color: map-get($colorDef, 'color');

  --ion-color-#{$colorName}: #{$color};
  --ion-color-#{$colorName}-rgb: #{rgb_str($color)};
  @if map-has-key($colorDef, 'contrast') {
    $contrast: map-get($colorDef, 'contrast');
    --ion-color-#{$colorName}-contrast: #{$contrast};
    --ion-color-#{$colorName}-contrast-rgb: #{rgb_str($contrast)};
  }
  @else {
    --ion-color-#{$colorName}-contrast: #{contrast($color)};
    --ion-color-#{$colorName}-contrast-rgb: #{rgb_str(contrast($color))};
  }
  @if map-has-key($colorDef, 'shade') {
    --ion-color-#{$colorName}-shade: #{shade($color)};
  }
  @if map-has-key($colorDef, 'tint') {
    --ion-color-#{$colorName}-tint: #{tint($color)};
  }

  .ion-color-#{$colorName} {
    --ion-color-base: var(--ion-color-#{$colorName});
    --ion-color-base-rgb: var(--ion-color-#{$colorName}-rgb);
    --ion-color-contrast: var(--ion-color-#{$colorName}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-#{$colorName}-contrast-rgb);
    @if map-has-key($colorDef, 'shade') {
      --ion-color-shade: var(--ion-color-#{$colorName}-shade);
    }
    @if map-has-key($colorDef, 'tint') {
      --ion-color-tint: var(--ion-color-#{$colorName}-tint);
    }
  }

  @if $darkColorDef != "" {
    body.dark {
      $darkColor: map-get($darkColorDef, 'color');

      --ion-color-#{$colorName}: #{$darkColor};
      --ion-color-#{$colorName}-rgb: #{rgb_str($darkColor)};
      @if map-has-key($darkColorDef, 'contrast') {
        $contrast: map-get($darkColorDef, 'contrast');
        --ion-color-#{$colorName}-contrast: #{$contrast};
        --ion-color-#{$colorName}-contrast-rgb: #{rgb_str($contrast)};
      }
      @else {
        --ion-color-#{$colorName}-contrast: #{contrast($darkColor)};
        --ion-color-#{$colorName}-contrast-rgb: #{rgb_str(contrast($darkColor))};
      }
      @if map-has-key($darkColorDef, 'shade') {
        --ion-color-#{$colorName}-shade: #{shade($darkColor)};
      }
      @if map-has-key($darkColorDef, 'tint') {
        --ion-color-#{$colorName}-tint: #{tint($darkColor)};
      }
    }
  }
}

@mixin generate-ionic-color($colorName, $color, $darkColor: "") {
  --ion-color-#{$colorName}: #{$color};
  --ion-color-#{$colorName}-rgb: #{rgb_str($color)};
  --ion-color-#{$colorName}-contrast: #{contrast($color)};
  --ion-color-#{$colorName}-contrast-rgb: #{rgb_str(contrast($color))};
  --ion-color-#{$colorName}-shade: #{shade($color)};
  --ion-color-#{$colorName}-tint: #{tint($color)};

  .ion-color-#{$colorName} {
    --ion-color-base: var(--ion-color-#{$colorName});
    --ion-color-base-rgb: var(--ion-color-#{$colorName}-rgb);
    --ion-color-contrast: var(--ion-color-#{$colorName}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-#{$colorName}-contrast-rgb);
    --ion-color-shade: var(--ion-color-#{$colorName}-shade);
    --ion-color-tint: var(--ion-color-#{$colorName}-tint);
  }

  @if $darkColor != "" {
    body.dark {
      --ion-color-#{$colorName}: #{$darkColor};
      --ion-color-#{$colorName}-rgb: #{rgb_str($darkColor)};
      --ion-color-#{$colorName}-contrast: #{contrast($darkColor)};
      --ion-color-#{$colorName}-contrast-rgb: #{rgb_str(contrast($darkColor))};
      --ion-color-#{$colorName}-shade: #{shade($darkColor)};
      --ion-color-#{$colorName}-tint: #{tint($darkColor)};
    }
  }
}

@mixin generate-bau-color($colorName, $colorDetails) {
  $color: map-get($colorDetails, 'color');
  $opacity: map-get($colorDetails, 'opacity');
  $opacity-hover: map-get($colorDetails, 'opacity-hover');
  $dark-opacity: map-get($colorDetails, 'dark-opacity');
  $dark-opacity-hover: map-get($colorDetails, 'dark-opacity-hover');
  --bau-color-#{$colorName}: #{$color};
  --bau-color-#{$colorName}-rgb: #{rgb_str($color)};
  --bau-color-#{$colorName}-opacity: #{$opacity};
  --bau-color-#{$colorName}-opacity-hover: #{$opacity-hover};

  body.dark {
    --bau-color-#{$colorName}-opacity: #{$dark-opacity};
    --bau-color-#{$colorName}-opacity-hover: #{$dark-opacity-hover};
  }
}

// Keep colors in sync with src/app/shared/colors.ts
:root {
  @include generate-ionic-color('primary', $bmPrimary);
  @include generate-ionic-color('success', $bmSuccess);
  @include generate-ionic-color('warning', $bmWarning);
  @include generate-ionic-color('danger', $bmRed);
  @include generate-ionic-color('text-danger', $bmTextDanger);
  @include generate-ionic-color('heading-primary', $bmNeutral12, $bmNeutral0);
  @include generate-ionic-color('text-primary', $bmNeutral12, $bmNeutral0);
  @include generate-ionic-color('text-secondary', $bmTextSecondary);
  @include generate-ionic-color('text-grey', $bmNeutral8, $bmNeutral2);
  @include generate-ionic-color('text-dark', $bmNeutral12, $bmNeutral0);
  @include generate-ionic-color('inactive', $bmInactive);
  @include generate-ionic-color('white', $bmWhite);
  @include generate-ionic-color('sketch-black', $bmNeutral12);
  @include generate-ionic-color('menu', $bmNeutral12, $bmNeutral12);
  @include generate-ionic-color('header-avatar', $bmNeutral2, $bmNeutral2);
  @include generate-ionic-color('brand', $bmBrand6, $bmBrand6);
  @include generate-ionic-color('omg-divider', $bmNeutral5, $bmNeutral7);
  @include generate-ionic-color('alternative', $bmAlternative);
  @include generate-ionic-color('omg-link', $bmAlternative, $bmNeutralDarkLink);
  @include generate-ionic-color-detailed('elevated-alt', (
    'color': $bmNeutral2,
    'contrast': $bmNeutral12
  ), (
    'color': $bmNeutral10,
    'contrast': $bmNeutral0
  ));
  @include generate-ionic-color-detailed('elevated-on-alt', (
    'color': $bmWhite,
    'contrast': $bmNeutral12
  ), (
    'color': $bmNeutral9,
    'contrast': $bmNeutral0
  ));
  @include generate-ionic-color-detailed('elevated', (
    'color': $bmWhite,
    'contrast': $bmNeutral12
  ), (
    'color': $bmNeutral10,
    'contrast': $bmNeutral0
  ));
  @include generate-ionic-color-detailed('bg-light', (
    'color': $bmNeutral1,
    'contrast': $bmNeutral12
  ), (
    'color': $bmNeutral11,
    'contrast': $bmNeutral0
  ));
  @include generate-ionic-color-detailed('omg-toast-bg', (
    'color': $bmWhite,
    'contrast': $bmNeutral12
  ), (
    'color': $bmNeutral9,
    'contrast': $bmNeutral1
  ));
  @include generate-ionic-color-detailed('omg-card-surface', (
    'color': $bmNeutral1,
    'contrast': $bmNeutral12,
  ), (
    'color': $bmNeutral11,
    'contrast': $bmNeutral0,
  ));
  @include generate-ionic-color-detailed('omg-sidebar-bg', (
    'color': $bmWhite,
    'contrast': $bmNeutral12,
  ), (
    'color': $bmNeutral11,
    'contrast': $bmNeutral0,
  ));
  @include generate-ionic-color-detailed('omg-toolbar-bg', (
    'color': $bmNeutral2,
    'contrast': $bmNeutral12,
  ), (
    'color': $bmNeutral11,
    'contrast': $bmNeutral0,
  ));
  @include generate-ionic-color('omg-modal-bg', $bmModalBackgroundLm, $bmModalBackgroundDm);
  @include generate-ionic-color('omg-border', $bmNeutral5, $bmNeutral7);
  @include generate-ionic-color('omg-split-line', $bmNeutral3, $bmNeutral10);
  @include generate-ionic-color('omg-dark-line', $bmNeutral8, $bmNeutral4);
  @include generate-ionic-color('omg-info-text', $bmNeutral10, $bmNeutral2);
  @include generate-ionic-color('neutral-dimmed', $bmNeutral6);
  @include generate-ionic-color('omg-dropdown-background', $bmWhite, $bmNeutral8);
  @include generate-ionic-color-detailed('neutral-badge', (
    'color': $bmNeutral4,
    'contrast': $bmNeutral12,
  ));
  @include generate-ionic-color-detailed('neutral-light-badge', (
    'color': $bmNeutral2,
    'contrast': $bmNeutral7,
  ), (
    'color': $bmNeutral8,
    'contrast': $bmNeutral4,
  ));
  @include generate-ionic-color-detailed('brand-accent', (
    'color': $bmBrandAccent,
    'contrast': $bmNeutral0,
  ));

  @each $name, $colorDetails in $bmAccents {
    @include generate-bau-color($name, $colorDetails);
  }
}

.ion-color-error {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

:root {
  --ion-text-color: #{$bmNeutral12};
  --ion-border-color: var(--ion-color-omg-border);
}

body.dark {
  --ion-background-color: #{$bmNeutral11};
  --ion-background-color-rgb: #{rgb_str($bmNeutral11)};
  --ion-text-color: #{$bmNeutral0};
  --ion-text-color-rgb: #{rgb_str($bmNeutral0)};
  --ion-toolbar-background: #0d0d0d;
  --ion-item-background: transparent;
  --ion-border-color: var(--ion-color-omg-border);

  //only for browsers
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus{
    -webkit-box-shadow: #000000;
    transition: background-color 5000s;
    -webkit-text-fill-color: white !important;
  }

  form ion-item {
    --background: var(--ion-background-color);
  }
}
