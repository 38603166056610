@use "./tokens/button";
@import "./new-ui";
@import "./mixins";
@import "./variables";



@mixin apply-single-style($def) {
  --background-default: #{map-get($def, 'background-default')};
  --color-default: #{map-get($def, 'color-default')};
  --box-shadow-default: #{map-get($def, 'box-shadow-default')};
  --border-width-default: #{map-get($def, 'border-width-default')};
  --border-color-default: #{map-get($def, 'border-color-default')};
  --background-hover: #{map-get($def, 'background-hover')};
  --color-hover: #{map-get($def, 'color-hover')};
  --box-shadow-hover: #{map-get($def, 'box-shadow-hover')};
  --border-color-hover: #{map-get($def, 'border-color-hover')};
  --background-active: #{map-get($def, 'background-active')};
  --color-active: #{map-get($def, 'color-active')};
  --box-shadow-active: #{map-get($def, 'box-shadow-active')};
  --border-color-active: #{map-get($def, 'border-color-active')};
}

@mixin apply-color($colorDef) {
  $lmDef: map-get($colorDef, 'lm');
  $dmDef: map-get($colorDef, 'dm');
  @if map-has-key($lmDef, 'primary') {
    &.omg-btn-primary {
      $def: map-get($lmDef, 'primary');
      @include apply-single-style($def);

      body.dark & {
        $def: map-get($dmDef, 'primary');
        @include apply-single-style($def);
      }
    }
  }
  @if map-has-key($lmDef, 'secondary') {
    &.omg-btn-secondary {
      $def: map-get($lmDef, 'secondary');
      @include apply-single-style($def);

      body.dark & {
        $def: map-get($dmDef, 'secondary');
        @include apply-single-style($def);
      }
    }
  }
  @if map-has-key($lmDef, 'text') {
    &.omg-btn-text {
      $def: map-get($lmDef, 'text');
      @include apply-single-style($def);

      body.dark & {
        $def: map-get($dmDef, 'text');
        @include apply-single-style($def);
      }
    }
  }
}

ion-button {
  margin: 0;
  letter-spacing: normal;
  white-space: nowrap; // Ionic 8 introduced text wrapping per default. This is to fall back to Ionic 7 behaviour
  &:not(.ion-no-padding) {
    --padding-start: #{$omgButtonPaddingStart};
    --padding-end: #{$omgButtonPaddingEnd};
  }
  --border-width: 1px;
  @include medium-font;
  text-transform: none !important;
  height: $omgButtonHeight;
  --background-hover-opacity: 0;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --ripple-color: transparent;

  &::part(native) {
    transition: background ease-out 200ms,
                border-color ease-out 200ms,
                color ease-out 200ms,
                box-shadow ease-out 200ms,
                opacity ease-out 200ms;
  }

  &,
  ion-buttons &.button,
  ion-buttons &.button.button-clear,
  ion-buttons &.button:not(.button-round),
  .ion-color &.button.button-clear {
    --border-radius: #{$omgButtonRadius};

    &:hover {
      color: var(--color);
    }
  }

  &.omg-btn-primary,
  &.omg-btn-secondary,
  &.omg-btn-text {
    &,
    ion-buttons &,
    ion-buttons &.button-clear,
    ion-buttons &:not(.button-round),
    .ion-color &.button.button-clear {
      --background: var(--background-default);
      --color: var(--color-default);
      --box-shadow: var(--box-shadow-default);
      --border-width: var(--border-width-default);
      --border-color: var(--border-color-default);
      --border-style: solid;

      &:hover:not(:active) {
        --background: var(--background-hover);
        --color: var(--color-hover);
        --box-shadow: var(--box-shadow-hover);
        --border-color: var(--border-color-hover);
      }

      &:active {
        --background: var(--background-active);
        --color: var(--color-active);
        --box-shadow: var(--box-shadow-active);
        --border-color: var(--border-color-active);
      }
    }

    &.in-toolbar:not(.ion-color):not(.in-toolbar-color)::part(native) {
      color: var(--color);
      background: var(--background);
    }
  }

  &.omg-btn-upload {
    height: auto;
    --padding-top: #{spacing(0.5)};
    --padding-bottom: #{spacing(0.5)};

    > :first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: spacing(0.5);
      flex-direction: column;

      text-align: center;
    }
  }

  &.omg-btn-popover-list {
    width: 100%;
    height: auto;
    --padding-top: #{spacing(0.75)};
    --padding-bottom: #{spacing(0.75)};
    --padding-start: #{spacing(1)};
    --padding-end: #{spacing(1)};

    .omg-btn-popover-list-label {
      flex-grow: 1;
      text-align: left;
      @include medium-font;
    }
  }

  &,
  ion-buttons &,
  ion-buttons &.button-clear,
  .ion-color &.button.button-clear {
    // Default color (neutral)
    @include apply-color(button.$btnDefault);

    &.omg-btn-color-neutral {
      @include apply-color(button.$btnNeutral);
    }
    &.omg-btn-color-brand {
      @include apply-color(button.$btnBrand);
    }
    &.omg-btn-color-danger {
      @include apply-color(button.$btnDanger);
    }
    &.omg-btn-color-popover-list {
      @include apply-color(button.$btnPopoverList);
    }
    &.omg-btn-color-selected {
      @include apply-color(button.$btnSelected);
    }
  }

  &.no-shadow {
    --box-shadow: none;
  }

  & > [slot=start] + * {
    margin-left: spacing(0.5);
  }
  & > * + [slot=end] {
    margin-left: spacing(0.5);
  }

  &.omg-btn-fab {
    height: $omgFabButtonSize;
    width: $omgFabButtonSize;
    --border-radius: #{$omgFabButtonRadius};
  }
}

.omg-btn-split {
  display: flex;
  gap: 2px;
  > ion-button:first-of-type {
    --border-radius: 12px 0 0 3px;
  }
  > ion-button:last-of-type {
    --border-radius: 0 3px 3px 0;
  }
  > ion-button:not(:first-of-type):not(:last-of-type) {
    --border-radius: 0;
  }
}
