@use "./color";
@use "./ui/variables";
@use "./ui/mixins";
@use "./ui/tokens/button" as button-tokens;

mbsc-datepicker[select="range"].mbsc-inline-style mbsc-input label.mbsc-input {
  display: inline-block;
}

input.mbsc-windows.mbsc-textfield-box {
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  &.mbsc-focus {
    box-shadow: none;
    border-color: unset;
  }
}

.mbsc-picker.mbsc-picker {
  .mbsc-ios.mbsc-control-w:before { 
    content: unset;
  }

  &.mbsc-input.mbsc-ios {
    &:after {
      border-color: color.$bmNeutral5;
      body.dark & {
        border-color: color.$bmNeutral7;
      }
    }
    &:focus-within, &:focus {
      &:after {
        border-color: color.$bmNeutral12;
        body.dark & {
          border-color: color.$bmNeutral5;
        }
      }
    }
  }

  &.mbsc-input .mbsc-control {
    color: var(--ion-text-color);
    border-bottom-color: color.$bmNeutral5;
    &:focus {
      border-bottom-color: color.$bmNeutral12;
    }

    body.dark & {
      border-bottom-color: color.$bmNeutral7;
      &:focus {
        border-bottom-color: color.$bmNeutral5;
      }
    }
  }

  &.mbsc-input {
    margin: 0;
  }
}


mbsc-temperature .mbsc-input,
mbsc-range-start .mbsc-input,
mbsc-range-end .mbsc-input,
mbsc-datepicker mbsc-input {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.reportForm mbsc-datepicker[select="range"] .mbsc-input{
  margin: 0px !important;
}
.reportForm mbsc-datepicker[select="range"].mbsc-ios mbsc-input input {
  width: calc((50vw - 20px));
}

.reportForm mbsc-datepicker[select="range"].mbsc-material mbsc-input input {
  width: calc((50vw - 25px));
}

@media (min-width: 767.99px) {
  .reportForm mbsc-datepicker[select="range"].mbsc-ios mbsc-input input {
    width: calc((25vw - 20px));
  }
  .reportForm mbsc-datepicker[select="range"].mbsc-picker mbsc-input input,
  .reportForm mbsc-datepicker[select="range"].mbsc-material mbsc-input input {
    width: calc((25vw - 25px));
  }
}

@media (min-width: 991.99px) {
  .reportForm mbsc-datepicker[select="range"].mbsc-ios mbsc-input input {
    width: 93px !important;
  }
  .reportForm mbsc-datepicker[select="range"].mbsc-picker mbsc-input input,
  .reportForm mbsc-datepicker[select="range"].mbsc-material mbsc-input input {
    width: 93px !important;
  }
}

.mbsc-windows.mbsc-button {
  font-size: 0.9em;
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
}

mbsc-datepicker.no-margin mbsc-input {
  margin: 0 !important;
}

mbsc-datepicker.no-padding mbsc-input input.mbsc-textfield {
  padding: 0;
}

mbsc-datepicker.no-border mbsc-input input.mbsc-textfield {
  border-bottom: 0 !important;
}

.mbsc-picker.mbsc-windows {
  .mbsc-font {
    font-family: var(--ion-font-family);
  }

  .mbsc-popup-buttons,
  .mbsc-popup-buttons.mbsc-windows {
    flex-direction: row;
    gap: mixins.spacing(0.25);
    padding: mixins.spacing(0.75);
    @mixin generate-styles($token) {
      background-color: map-get($token, 'background-default');
      color: map-get($token, 'color-default');
      &:hover:not(:active) {
        background-color: map-get($token, 'background-hover');
        color: map-get($token, 'color-hover');
        opacity: 1;
      }

      &:active {
        background-color: map-get($token, 'background-active');
        color: map-get($token, 'color-active');
        opacity: 1;
      }
    }
    mbsc-button.mbsc-button-flat {
      border: none;
      border-radius: variables.$omgButtonRadius;
      min-height: variables.$omgButtonHeight;
      line-height: variables.$omgLineHeight;
    }

    mbsc-button.mbsc-popup-button-primary {
      @include generate-styles(map-get(map-get(button-tokens.$btnDefault, 'lm'), 'primary'));

      body.dark & {
        @include generate-styles(map-get(map-get(button-tokens.$btnDefault, 'dm'), 'primary'));
      }
    }
  }
}
