@use "sass:math";
@use "../mixins" as old-mixins;
@use "../color";
@use "./tokens/button" as button-tokens;
@use "./variables";
@use "./checkbox";
@use "./radio";
@use "./typography";
@import "./new-ui";
@import "./mixins";

ion-item-divider {
  @include typography.omg-body-bold;
  --color: #{color.$bmNeutral7};

  body.dark &:not(.omg-t-header-3):not(.omg-t-header-3-bold) {
    --color: #{color.$bmNeutral4};
  }

  &.omg-t-header-3,
  &.omg-t-header-3-bold {
    --color: var(--ion-text-color);
  }
}

ion-item {
  --background-hover: transparent;
  --background-focused: transparent;
  --ripple-color: transparent;
  --highlight-color-focused: #{color.$bmNeutral12};
  --highlight-color-valid: #{color.$bmNeutral12};
  --full-highlight-height: 0;
  &, &.item ion-input {
    --placeholder-color: #{color.$bmNeutral7};
    --placeholder-opacity: 1;
  }

  body.dark & {
    --highlight-color-focused: #{color.$bmNeutral5};
    --highlight-color-valid: #{color.$bmNeutral5};
    &, &.item ion-input {
      --placeholder-color: #{color.$bmNeutral4};
      --placeholder-opacity: 1;
    }
  }

  &.item ion-label > p {
    color: color.$bmNeutral6;
  }

  // todo: transition
  // TODO: not all items should have hover abilities

  &.active-item {
    --background: #{color.$bmNeutral3};

    body.dark & {
      --background: #{color.$bmNeutral9};
    }

    .omg-hoverable &:hover:not(.omg-prevent-hover) {
      --background: #{color.$bmNeutral3};

      body.dark & {
        --background: #{color.$bmNeutral8};
      }
    }
  }

  .omg-hoverable &:hover:not(.omg-prevent-hover) {
    --background: #{color.$bmNeutral1};

    body.dark & {
      --background: #{color.$bmNeutral10};
    }
  }

  &:focus {
    --background: #{color.$bmNeutral2};

    body.dark & {
      --background: #{color.$bmNeutral9};
    }
  }

  &.item-has-focus.item-has-focus ion-label:not(.ion-color) {
    &.label-floating,
    &.label-stacked {
      color: var(--color);
    }
  }

  ionic-selectable {
    --icon-color: #{$bmNeutral6};
  }

  @include new-ui {
    --highlight-height: 1px;
    --padding-start: #{spacing(0.5)} !important;
    --padding-end: #{spacing(0.5)} !important;
    --inner-padding-end: 0px !important;

    &[disabled] {
      opacity: 0.5;
    }

    @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
      & > [slot="end"] {
        margin-left: unset;
        margin-inline-start: var(--slot-gap, #{spacing(0.5)});
      }
    }

    & > [slot="end"] {
      margin-left: var(--slot-gap, #{spacing(0.5)});
    }

    @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
      & > [slot="start"] {
        margin-right: unset;
        margin-inline-end: var(--slot-gap, #{spacing(0.5)});
      }
    }

    & > [slot="start"] {
      margin-right: var(--slot-gap, #{spacing(0.5)});
    }

    &.ion-no-padding[lines="inset"],
    &.ion-no-padding[lines="none"] {
      --inner-padding-start: 0 !important;
      --inner-padding-end: 0 !important;
      --padding-start: 0 !important;
      --padding-end: 0 !important;

      &.item-interactive.item-has-focus,
      &.item-interactive:has(.has-focus),
      &.item-interactive.ion-touched.ion-invalid {
        --border-color: transparent;
      }
    }

    &.ion-invalid {
      ion-input,
      ion-label {
        &.ion-color {
          color: var(--ion-color-danger);
        }
      }
    }

    &.ion-invalid.ion-dirty.omg-can-error-text {
      --inner-border-width: 0;
    }

    &.helper-text {
      font-size: $omgFontSizeSmall;
      line-height: 1.5;
      --min-height: auto;
      padding: var(--padding, #{spacing(0.5)} 0);

      &.without-padding {
        --inner-padding-start: 0 !important;
        --inner-padding-end: 0 !important;
        --padding-start: 0 !important;
        --padding-end: 0 !important;
      }
    }

    & ion-button.three-dots {
      font-size: 19px;

      --padding-start: 2px;
      --padding-end: 2px;
    }
  }
}

@mixin in-modal-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  --inner-border-width: 0;
  --padding-start: #{spacing(2)} !important;
  --padding-end: #{spacing(2)} !important;

  --slot-gap: #{spacing(1)};

  --color: #{color.$bmNeutral12};
  body.dark & {
    --color: #{color.$bmNeutral0};
  }

  ion-checkbox {
    @include checkbox.omg-checkbox;
  }

  ion-radio {
    @include radio.omg-radio;
  }
}

@mixin in-modal-list-item-divider {
  --inner-border-width: 0;
  --padding-start: #{spacing(2)} !important;
  --padding-end: #{spacing(2)} !important;

  font-weight: bold;
  height: 48px;
  align-items: flex-end;
  padding-bottom: spacing(0.5);
  border-bottom: none;

  @include normal-font;

  --color: #{color.$bmNeutral7};
  body.dark & {
    --color: #{color.$bmNeutral4};
  }
}

.omg-in-modal-list ion-item {
  @include in-modal-list-item;
}

ion-item {
  @include new-ui {
    .omg-in-modal-list & {
      @include in-modal-list-item;
    }
  }
}

.omg-in-modal-list ion-item-divider {
  @include in-modal-list-item-divider;
}

ion-item-divider {
  @include new-ui {
    .omg-in-modal-list & {
      @include in-modal-list-item-divider;
    }
  }
}

ion-item.like-button {
  $lmBorder: map-get(map-get(map-get(button-tokens.$btnDefault, 'lm'), 'secondary'), 'border-color-default');
  $dmBorder: map-get(map-get(map-get(button-tokens.$btnDefault, 'dm'), 'secondary'), 'border-color-default');
  --min-height: auto;
  --border-color: #{$lmBorder};
  --border-radius: #{variables.$omgButtonRadius};
  body.dark & {
    --border-color: #{$dmBorder};
  }
  &.like-button.like-button {
    --border-width: 1px;
  }
}
