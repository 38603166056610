@use "./alert";
@use "./attention";
@use "./checkbox";
@use "./divider";
@use "./inline-input";
@use "./link";
@use "./slim-searchbar";
@use "./form";
@use "./reorder";
@use "./mixins";
@use "./notification";
@import "./layout";
@import "./modal";
@import "./utils";

.omg-form {
  @include form.omg-form;
}

.omg-layout,
ion-item.item > ion-label.omg-layout {
  @include omg-layout;
}

.omg-layout-column,
ion-item.item > ion-label.omg-layout-column {
  @include omg-layout-column;
}

ion-modal {
  @include omg-modal;
}

@include omg-modal-typography;

.omg-divider {
  @include divider.omg-divider;
}

button.omg-inline-input {
  @include mixins.clear-button;
  line-height: 1.5;
}

button.clear-button {
  @include mixins.clear-button;
}

.omg-inline-input,
button.omg-inline-input {
  @include inline-input.omg-inline-input;
}

ion-alert {
  @include alert.omg-alert;
}

ion-searchbar.slim-searchbar {
  @include new-ui {
    @include slim-searchbar.omg-slim-searchbar;
  }
}

ion-checkbox.omg-checkbox {
  @include checkbox.omg-checkbox(false);
}

ion-checkbox.omg-checkbox-round {
  @include checkbox.omg-checkbox(true);
}

ion-reorder {
  @include reorder.omg-reorder;
}

.omg-attention {
  @include attention.omg-attention;
}

.omg-notification-badge {
  @include notification.omg-notification-badge;
}

:root {
  --omg-base-spacing: #{spacing(1)};
}

.omg-link {
  @include link.omg-link;
}
