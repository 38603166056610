@keyframes pulse-record {
  0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  
  70% {
      transform: scale(2);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  
  100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}
