@use "../color";

@mixin omg-radio {
  margin-top: 0;
  margin-bottom: 0;

  --color: #{color.$bmNeutral5};
  --color-checked: #{color.$bmNeutral5};

  &::part(mark) {
    background: color.$bmNeutral7;
  }
}

// Ionic 7 introduced a ion-radio height of 100% resulting in checkboxes with a higher height. 24px is the height it was before
ion-radio[label-placement] {
  height: 24px;
}