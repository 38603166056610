@import "color";
@import "variables";

@mixin lightOrDark($property, $light, $dark) {
  #{$property}: #{$light};

  :host-context(.dark) & {
    #{$property}: #{$dark};
  }
}

@mixin lightOrDarkGlobal($property, $light, $dark) {
  #{$property}: #{$light};

  body.dark & {
    #{$property}: #{$dark};
  }
}

@mixin one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin badge {
  background: var(--background);
  color: var(--color);
  padding: var(--padding, 3px 4px 1px);
  border-radius: var(--border-radius, $bauBorderRadiusSmall);
  font-size: var(--font-size, 14px);
  line-height: var(--line-height, 17px);
}

@function spacing($size) {
  @return $size * $bmSpacingStep;
}

@mixin is-camera-supported {
  :host-context(html.plt-android),
  :host-context(html.plt-ios),
  :host-context(html.plt-pwa) {
    @content;
  }
}

@mixin is-audio-supported {
  :host-context(html.plt-cordova.plt-android),
  :host-context(html.plt-cordova.plt-ios) {
    @content;
  }
}

@mixin is-not-camera-supported {
  :host-context(html:not(.plt-android):not(.plt-ios):not(.plt-pwa)) {
    @content;
  }
}

@mixin is-not-audio-supported {
  :host-context(html:not(.plt-cordova.plt-android):not(.plt-cordova.plt-ios)) {
    @content;
  }
}


@mixin is-audio-and-camera-supported {
  :host-context(html.plt-cordova.plt-android),
  :host-context(html.plt-cordova.plt-ios) {
    @content;
  }
}

@mixin is-not-audio-and-camera-supported {
  :host-context(html.plt-android:not(.plt-cordova)),
  :host-context(html.plt-ios:not(.plt-cordova)),
  :host-context(html.plt-pwa:not(.plt-cordova)) {
    @content;
  }
}

@mixin is-not-audio-and-not-camera-supported {
  :host-context(html:not(.plt-android):not(.plt-ios):not(.plt-pwa):not(.plt-cordova)) {
    @content;
  }
}
