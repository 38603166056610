@use "./typography";

// Typography utils

.omg-t-header-1 {
  @include typography.omg-header-1;
}
.omg-t-header-1-bold {
  @include typography.omg-header-1-bold;
}
.omg-t-header-2 {
  @include typography.omg-header-2;
}
.omg-t-header-2-bold {
  @include typography.omg-header-2-bold;
}
.omg-t-header-3 {
  @include typography.omg-header-3;
}
.omg-t-header-3-bold {
  @include typography.omg-header-3-bold;
}
.omg-t-body {
  @include typography.omg-body;
}
.omg-t-body-bold {
  @include typography.omg-body-bold;
}
.omg-t-medium-body {
  @include typography.omg-medium-body;
}
.omg-t-medium-body-bold {
  @include typography.omg-medium-body-bold;
}
.omg-t-xs-body {
  @include typography.omg-xs-body;
}
.omg-t-xs-body-bold {
  @include typography.omg-xs-body-bold;
}
.omg-t-info {
  @include typography.omg-info;
}
.omg-t-info-bold {
  @include typography.omg-info-bold;
}
.omg-t-hint {
  @include typography.omg-hint;
}
.omg-t-hint-bold {
  @include typography.omg-hint-bold;
}

.omg-f-italic {
  font-style: italic;
}

.omg-w-bold {
  font-weight: 600;
}

.omg-w-normal {
  font-weight: normal;
}

// Misc

.omg-clean-button {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  outline: none;
  background: inherit;
}
