@use "../../../../scss/ui/button";
@use "../../../../scss/ui/mixins";
@use "../../../../scss/ui/tokens/button" as button-tokens;
@use "../../../../scss/ui/variables";
@use "../../../../scss/breakpoint";
@use "../../../../scss/color";

$btnSelectedLightBrand: (
  "lm": (
    "secondary": (
      "background-default": color.$bmBrand2,
      "color-default": color.$bmNeutral12,
      "border-width-default": 1px,
      "box-shadow-default": "none",
      "border-color-default": color.$bmNeutral8,

      "background-hover": mix(color.$bmBrand2, color.$bmBrand4),
      "color-hover": color.$bmNeutral12,
      "box-shadow-hover": variables.$omgButtonHoverShadow,
      "border-color-hover": color.$bmNeutral8,

      "background-active": color.$bmBrand4,
      "color-active": color.$bmNeutral12,
      "box-shadow-active": variables.$omgButtonHoverShadow,
      "border-color-active": color.$bmNeutral12
    ),
  ),
  "dm": (
    "secondary": (
      "background-default": rgba(color.$bmBrand2, .24),
      "color-default": color.$bmNeutral0,
      "border-width-default": 1px,
      "box-shadow-default": "none",
      "border-color-default": color.$bmNeutral4,

      "background-hover": rgba(color.$bmBrand2, .36),
      "color-hover": color.$bmNeutral0,
      "box-shadow-hover": "none",
      "border-color-hover": color.$bmNeutral4,

      "background-active": rgba(color.$bmBrand2, .48),
      "color-active": color.$bmNeutral0,
      "box-shadow-active": "none",
      "border-color-active": color.$bmNeutral1
    ),
  ),
);

$btnSelectedBrand: (
  "lm": (
    "secondary": (
      "background-default": color.$bmBrand4,
      "color-default": color.$bmNeutral12,
      "border-width-default": 1px,
      "box-shadow-default": "none",
      "border-color-default": color.$bmNeutral8,

      "background-hover": mix(color.$bmBrand4, color.$bmBrand6),
      "color-hover": color.$bmNeutral12,
      "box-shadow-hover": variables.$omgButtonHoverShadow,
      "border-color-hover": color.$bmNeutral8,

      "background-active": color.$bmBrand6,
      "color-active": color.$bmNeutral12,
      "box-shadow-active": variables.$omgButtonHoverShadow,
      "border-color-active": color.$bmNeutral12
    ),
  ),
  "dm": (
    "secondary": (
      "background-default": rgba(color.$bmBrand4, .24),
      "color-default": color.$bmNeutral0,
      "border-width-default": 1px,
      "box-shadow-default": "none",
      "border-color-default": color.$bmNeutral4,

      "background-hover": rgba(color.$bmBrand4, .36),
      "color-hover": color.$bmNeutral0,
      "box-shadow-hover": "none",
      "border-color-hover": color.$bmNeutral4,

      "background-active": rgba(color.$bmBrand4, .48),
      "color-active": color.$bmNeutral0,
      "box-shadow-active": "none",
      "border-color-active": color.$bmNeutral1
    ),
  ),
);

.unit-selector-tree-scrollable-levels {
  overflow: auto;

  ion-button {
    &.level-selected:not(.level-has-selected) {
      @include button.apply-color(button-tokens.$btnSelected);

      body.dark &.omg-btn-secondary {
        --background-default: #{color.$bmNeutral10};
      }
    }
    &.level-selected.level-has-selected {
      @include button.apply-color($btnSelectedBrand);
    }
    &:not(.level-selected).level-has-selected {
      @include button.apply-color($btnSelectedLightBrand);
    }
  }
}

@include breakpoint.breakpoint-down('sm') {
  .unit-selector-tree-footer-buttons {
    display: flex;
    flex-grow: 1;
    width: 100%;

    > * {
      flex: 1 1 50%;
    }
  }
}

.unit-selector-list-item {
  padding-top: mixins.spacing(0.5);
  padding-bottom: mixins.spacing(0.5);
}
