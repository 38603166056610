@import "./color";

$bauFabMargin: 80px;

$bauBorderRadiusSmall: 4px;
$bauBorderRadiusMedium: 8px;

$bmSpacingStep: 10px;
$bauStatusSize: 32px;

$smallMenuWidth: 64px;
$menuWidth: 270px;

$widgetWidth: 360px;

$bauProtocolFirstToolbarHeight: 70px;
$bauProtocolSecondToolbarHeight: 24px;

$breakpoints: ("xs": 0px, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": (1440px + $smallMenuWidth));

$bmSlimCardBoxShadow: 0px 1px 3px rgba($bmSlimCardShadowRgb, 0.1),
                      0px 1px 2px rgba($bmSlimCardShadowRgb, 0.06);

$bmSlimCardBoxShadowElevated: 0px 2px 3px rgba($bmSlimCardShadowRgb, 0.15),
                              0px 0px 10px rgba($bmSlimCardShadowRgb, 0.1);

$bmProjectSelectorColWidth: 345px;
$bmProjectSmallSelectorColWidth: 250px;

$protocolEntryMaxWidth: 848px;
