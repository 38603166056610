@use "sass:math";
@use "../color";
@use "./mixins";

$checkboxSize: 20px;

@mixin omg-checkbox-radius-round {
  --border-radius: #{math.div($checkboxSize, 2)};
}

@mixin omg-checkbox-radius-square {
  --border-radius: 2px;
}

@mixin omg-checkbox($round: true) {

  @if $round == true {
    @include omg-checkbox-radius-round;
  } @else {
    @include omg-checkbox-radius-square;
  }

  --size: #{$checkboxSize};
  --checkbox-background: transparent;
  --border-color: #{color.$bmNeutral5};
  --checkbox-background-checked: #{color.$bmNeutral7};
  --border-color-checked: #{color.$bmNeutral7};
  
  body.dark & {
    --border-color: #{color.$bmNeutral7};
    --checkbox-background-checked: #{color.$bmNeutral7};
    --border-color-checked: #{color.$bmNeutral7};
  }

  &::part(label) {
    margin-top: 0;
    margin-bottom: 0;
    white-space: initial;
  }

  &::part(container) {
    padding: 2px;
  }
}

@mixin omg-checkbox-icon {
  margin-top: 0;
  margin-bottom: 0;

  margin-right: mixins.spacing(0.75);

  @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
      margin-right: unset;
      margin-inline-end: mixins.spacing(0.75);
  }

  color: #{color.$bmNeutral5};
  --ion-color-base: #{color.$bmNeutral7} !important;
  
  body.dark & {
    color: #{color.$bmNeutral7};
    --ion-color-base: #{color.$bmNeutral7} !important;
  }
}
