.native-input.sc-ion-input-ios {
//  padding-top: 10px !important;
//  padding-bottom: 10px !important;
}

ion-title.ios {
 // padding-left: 130px;
}

ion-modal ion-header.ios {
  padding-top: 20px;
}


.sc-ion-searchbar-ios-h {
  // height: 40px !important;
}


ion-toolbar .sc-ion-searchbar-ios-h {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
