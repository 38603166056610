@use 'sass:math';
@import './variables';
@import './color';

$borderThickness: 4px;
$borderColor: var(--ion-color-primary);
$iconSize: 12px;

@mixin bau-swiper-icon-transform($inverted: false) {
  @if ($inverted) {
    transform: translateX(-(math.div($iconSize, 4))) rotate(-45deg);
  } @else {
    transform: translateX(math.div($iconSize, 4)) rotate(-45deg);
  }
} 

@mixin bau-swiper() {
  swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: $bmWhite;
    border-radius: 50%;
    box-shadow: $bmSlimCardBoxShadow;
    background-image: none;
    
    transition: opacity 160ms ease 0s;

    &::after {
      content: "";
      width: $iconSize;
      height: $iconSize;
      border-radius: $borderThickness;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    &::after {
      border-top: $borderThickness solid $borderColor;
      border-left: $borderThickness solid $borderColor;
      @include bau-swiper-icon-transform(false);
    }
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    &::after {
      border-bottom: $borderThickness solid $borderColor;
      border-right: $borderThickness solid $borderColor;
      @include bau-swiper-icon-transform(true);
    }
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
}

swiper-container {
  --swiper-theme-color: var(--ion-color-primary);
  @include bau-swiper();
}
