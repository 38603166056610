@use "./variables";

// Cred: https://jdsteinbach.com/css/sass-maps-breakpoint-mixin/

@mixin breakpoint-up($name) {
  @if not map-has-key(variables.$breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get(variables.$breakpoints, $name)) {
      @content;
    }
  }
}

@mixin breakpoint-down($name) {
  @if not map-has-key(variables.$breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (max-width: (map-get(variables.$breakpoints, $name) - 1px)) {
      @content;
    }
  }
}

@mixin breakpoint-between($minName, $maxName) {
  @include breakpoint-up($minName) {
    @include breakpoint-down($maxName) {
      @content;
    }
  }
}
