@use "./mixins";
@use "./variables";
@import "../color";

@mixin omg-alert {
  @at-root & {
    body.dark & {
      --background: var(--ion-color-bg-light);
    }

    &.with-user-select .alert-message {
      user-select: text;
    }

    &.medium-alert .alert-wrapper {
      --max-width: 400px;
    }

    & .alert-wrapper {
      --min-width: 320px;
      --max-width: 330px;
      &.alert-wrapper {
        border-radius: variables.$omgBrandBorderRadiusBig;
      }
      box-shadow: variables.$omgIonicBoxShadow;

      & .alert-head+.alert-message {
        padding: mixins.spacing(0.75) mixins.spacing(1.5);
      }

      & .alert-head {
        padding: mixins.spacing(1) mixins.spacing(1.5);

        @include mixins.extra-large-font;

        & .alert-title {
          line-height: 1.5;
        }
      }

      & .alert-message {
        padding: mixins.spacing(1) mixins.spacing(1.5) mixins.spacing(0.75);

        @include mixins.medium-font;

        color: var(--ion-text-color);
      }

      & .alert-button-group {
        padding: mixins.spacing(0.75);
        gap: mixins.spacing(0.5);
        flex-direction: row !important;

        & .alert-button {
          text-transform: none;
          margin: 0;
          padding: mixins.spacing(0.5) mixins.spacing(0.75);
          border-radius: variables.$omgBrandBorderRadiusSmall;

          @include mixins.medium-font;

          background: transparent;

          color: $bmAlternative;

          &.alert-button-solid {
            background: var(--ion-color-base);
            color: var(--ion-color-contrast);
            box-shadow: variables.$omgBoxShadow;
          }
          
          &.alert-button-outline {
            border: 1px solid var(--ion-color-base);
            color: var(--ion-color-base);
            box-shadow: variables.$omgBoxShadow;
          }

          &.alert-button-x-to-close {
            position: absolute;
            top: 0px;
            right: 0px;
            font-weight: 100;
            font-size: variables.$omgFontSizeExtraLarge;
            padding-right: mixins.spacing(1);
          }

          &:not(.alert-button-outline):not(.alert-button-solid) {
            color: var(--ion-color-base, var(--ion-color-text-primary, #{$bmAlternative}));
          }
        }
      }
    }
  }
}
