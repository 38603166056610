$bmBlue: #2691C8;
$bmBlue2: #2faff2;

$bmWhite: #FFFFFF;
$bmBlack: #000000;

$bmPrimary: #2691C8;
$bmRed: #AD003E;

$bmSuccess: #78C832;
$bmWarning: #EFB003;
$bmTextDanger: #BE4968;

$mbsc-frame-accent-light: $bmBlue;
$mbsc-frame-accent-dark: $bmWhite;
$mbsc-frame-background-dark: #0d0d0d;

$bmSlimCardShadowRgb: rgb(16, 24, 40);

$bmAccents: (
  "blue": (
    "color": #2180B0,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "red": (
    "color": #A6003B,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "yellow": (
    "color": #B48206,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "green": (
    "color": #44711C,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "dark-blue": (
    "color": #1A3A99,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "orange": (
    "color": #B15900,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "purple": (
    "color": #7612A1,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "pink": (
    "color": #8C006D,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "brown": (
    "color": #905E2B,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
  "grey": (
    "color": #666666,
    "opacity": 0.15,
    "opacity-hover": 0.3,
    "dark-opacity": 0.35,
    "dark-opacity-hover": 0.55,
  ),
);

$bmNeutral12: #1D252E;
$bmNeutral11: #222A32;
$bmNeutral10: #2B333C;
$bmNeutral9: #3A424C;
$bmNeutral8: #4F5964;
$bmNeutral7: #6C7682;
$bmNeutral6: #8C98A5;
$bmNeutral5: #B0BAC5;
$bmNeutral4: #CFD6DF;
$bmNeutral3: #E7EBF1;
$bmNeutral2: #EFF2F6;
$bmNeutral1: #F8F9FB;
$bmNeutral0: #FFFFFF;

$bmNeutralDarkLink: #55C0F7;

$bmInactive: $bmNeutral5;

$bmCardBorder: $bmInactive;

$bmModalBackgroundLm: $bmNeutral0;
$bmModalBackgroundDm: $bmNeutral9;

$bmTextSecondary: $bmNeutral6;

$bmAlternative: #004F74;

$bmBrand6: #FAB500;
$bmBrand4: #FFD054;
$bmBrand2: #FFE1A9;

$bmBrandAccent: #C30079;

$bmRed4: #FF91AE;
$bmRed3: #AD003E;
$bmRed2: #C7345B;
$bmRed1: #CD2C57;

$ganttBorderLm: $bmNeutral4;
$ganttBorderDm: $bmNeutral8;

$bmBadgeBlueBackgroundLm: #D9E9F1;
$bmBadgeBlueBackgroundDm: #134662;

$bmBadgeRedBackgroundLm: #F3D9E2;
$bmBadgeRedBackgroundDm: #721239;

$bmBadgeYellowBackgroundLm: #FEF4D9;
$bmBadgeYellowBackgroundDm: #635323;

$bmBadgeGreenBackgroundLm: #EBF7E0;
$bmBadgeGreenBackgroundDm: #3C5932;
