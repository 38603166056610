@use "./mixins";
@use "../mixins" as old-mixins;
@import "./new-ui";
@import "../color";
@import "../variables";

ion-toast {

  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: -50px;
  }
  &:nth-of-type(3) {
    top: -100px;
  }
  &:nth-of-type(4) {
    top: -150px;
  }
  &:nth-of-type(5) {
    top: -200px;
  }

  @include new-ui {
    --background: var(--ion-color-omg-toast-bg);
    --color: var(--ion-color-omg-toast-bg-contrast);
    --border-radius: #{$omgMediumBorderRadius};
    @include mixins.active-elevation(true);

    &::part(button),
    &::part(close-button) {
      color: var(--ion-color-omg-toast-bg-contrast);
    }

    &::part(button) {
      margin-bottom: mixins.spacing(1);
    }

    $closeButtonPadding: 9px;
    $closeButtonSize: 36px;

    &::part(close-button) {
      position: absolute;
      top: mixins.spacing(1) - $closeButtonPadding;
      right: mixins.spacing(1) - $closeButtonPadding;
    }

    &::part(button-group) {
      justify-content: flex-end;
      margin: 0 mixins.spacing(1);
      gap: 0 mixins.spacing(0.5);
      flex-wrap: wrap;
    }

    &::part(header) {
      font-weight: bold;
    }

    &::part(header),
    &::part(message) {
      padding-right: $closeButtonSize - $closeButtonPadding;
    }

    &::part(container) {
      flex-direction: column;
      align-items: stretch;
    }
    
    &::part(content) {
      padding: mixins.spacing(1);
    }
  }
}
