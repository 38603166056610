@use "../scss/ui/mixins";
@use "../scss/breakpoint";
@import "../../theme/variables.scss";
@import "color";
@import "mixins";
@import "variables";
@import "../scss/ui/mixins";
@import "../scss/ui/variables";

@media (min-width: map-get($breakpoints, "xxl")) {
  .ion-hide-xxl-up {
    display: none !important;
  }
}
@media (max-width: (map-get($breakpoints, "xxl") - 1px)) {
  .ion-hide-xxl-down {
    display: none !important;
  }
}

@media (min-width: map-get($breakpoints, 'sm')) {
  .margin-right-10-sm-up {
    margin-right: spacing(1);
  }
}

ion-button {
  &.link-button {
    text-decoration: underline;
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
    --ripple-color: transparent;
    --background-hover-opacity: 0;
  }
}

ion-textarea.no-bottom-line-when-focus {
  --highlight-height: 0;
}


ion-item {
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  --inner-padding-end: 0px !important;
  padding-inline-end: 0px !important;
  flex: 1 1 0 !important;
  flex-wrap: nowrap!important;

}

ion-item:has(ion-checkbox.checkbox-disabled),
ion-item:has(ion-radio.radio-disabled) {
  cursor: default;
}

ion-card {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

ion-footer {
  order: unset;
}

// a card like style
.app-ion-card {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  --color: var(--ion-color-step-450);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid var(--ion-color-omg-divider);
  transition: background-color 160ms ease 0s, color 160ms ease 0s;

  &.active-item {
    border-color: var(--ion-color-omg-divider-active);
    box-shadow: 0px 5px 15px rgba(var(--ion-text-color-rgb), 0.15);
  }
}

ion-text {
  line-height: 20px;
}

ion-badge {
  margin-left: 5px !important;
}

//ionic-select
ion-popover.select-popover.wider-popover::part(content) {
  width: 340px;
}

// That class used to be part of ionic-selectable 4 but was removed in 5 but changed layout
.ionic-selectable:not(.ionic-selectable-has-label) {
  max-width: 100%;
  width: 100%;
}

//ionic-selectable
.ionic-selectable-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.project-selector ion-item.ionic-selectable-item {
  @media (max-width: map-get($breakpoints, 'md')) {
    --min-height: 40px;
  }
}

ion-modal::part(content) {
  @media only screen and (min-width: 768px) and (max-height: 600px) {
    --width: 600px;
  }
}

//ionic-modals
ion-modal.full-screen-modal::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 80%;
    min-width: 670px;
    max-width: 1000px;
  }

  @media (min-width: 1200px) {
    min-width: 1000px;
  }

  @media (max-width: map-get($breakpoints, 'xl')) {
    top: 0;
  }
}

ion-modal.successful-purchase-modal::part(content) {
  width: 600px;
  height: 750px;
}

ion-modal.basic-modal::part(content) {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    top: 0;
    @if variable-exists(--viewport-height) {
      --height: var(--viewport-height);
    }
  }

  @media (min-width: 768px) {
    max-width: 600px;
    max-height: 700px;
  }
}

ion-modal.update-modal::part(content) {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 576px) {
    max-height: 296px;
  }

  @media (min-width: 576px) {
    max-width: 320px;
    max-height: 296px;
  }
}

ion-modal.basic-modal-small::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    top: 0;
    @if variable-exists(--viewport-height) {
      --height: var(--viewport-height);
    }
  }

  @media (min-width: 768px) {
    max-width: 600px;
    max-height: 400px;
  }
}

ion-modal.alert-modal::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 330px;
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
}

ion-modal.basic-modal-x-small::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (max-width: 576px) {
    top: 0;
    @if variable-exists(--viewport-height) {
      --height: var(--viewport-height);
    }
  }

  @media (min-width: 576px) {
    max-width: 450px;
    max-height: 300px;
  }
}

ion-modal.basic-modal-x-small-high::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (max-width: 576px) {
    top: 0;
    @if variable-exists(--viewport-height) {
      --height: var(--viewport-height);
    }
  }

  @media (min-width: 576px) {
    max-width: 450px;
    max-height: 500px;
  }
}

ion-modal.full-screen-sketch::part(content) {
  width: 100%;
  height: 100%;
  --max-height: 100vh;
}

ion-modal.full-screen-modal-xxl::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 80%;
      max-width: 1920px;
  }

  @media (max-width: map-get($breakpoints, 'xl')) {
    top: 0;
  }
}

ion-modal.protocol-entry-modal::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    max-width: 800px;
  }

  @media (max-width: map-get($breakpoints, 'xl')) {
    top: 0;
  }
}

ion-modal.xxl-modal::part(content) {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;

  max-width: 1400px;
}

ion-modal.max-90-percent-modal::part(content) {
  position: absolute;
  display: block;
  width: 100%;
  @media (min-width: map-get($breakpoints, 'md')) {
    max-height: 90%;
    height: 90%;
  }

  max-width: 1440px;
}

ion-modal.max-950-modal::part(content) {
  max-height: 950px;
  top: unset;
}

ion-modal.half-screen-modal::part(content) {
  width: 100%;
  position: absolute;
  height: 400px;

  @media (max-height: 355px) {
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 80%;
    min-width: 670px;
    max-width: 1000px;
  }

  @media (min-width: 1200px) {
    min-width: 1000px;
  }

  @media (max-width: map-get($breakpoints, 'xl')) {
    top: 0;
  }
}

ion-modal.buying-modal::part(content) {
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    max-width: 600px;
    max-height: 700px;
  }
}

ion-modal.candu-modal::part(content) {
  width: 600px;
  height: 800px;
}

ion-modal.protocol-entry-modal.half-screen-modal::part(content) {
  @media (min-width: 768px) {
    max-width: 800px;
    width: 100%;
  }
}

ion-modal.modal-position-bottom::part(content) {
  bottom: 0px;
  position: absolute;

  @media (min-width: 768px) {
    width: 80%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
ion-range {
  --bar-background: #ffffff !important;
}

ion-modal.sketch-modal::part(content) {
  @media only screen and (min-width: 768px) and (max-height: 600px) {
    --width: 100% !important;
    --height: 100% !important;
  }
}

.sketch-modal {
  --width: 100% !important;
  --height: 100% !important;
  &.signature {
    @media only screen and (min-width: 700px) {
      --width: 700px !important;
    }
    --height: 350px !important;
  }
}

ion-modal.pdf-workflow-modal,
ion-modal.report-workflow-modal {
  &,
  &::part(content) {
    @media only screen and (min-width: map-get($breakpoints, 'lg')) {
      --width: #{map-get($breakpoints, 'lg')} !important;
    }
    @media only screen and (min-width: 1000px) {
      --width: 1000px !important;
    }
  }
  @media (max-width: map-get($breakpoints, 'md')) {
    --height: var(--viewport-height, 100%);
  }
  @media (min-width: map-get($breakpoints, 'md')) {
    --height: 90%;
  }
}

.entry-grouped-menu {
  --width: auto;
}

.spinner-center-button {
  position: absolute;
  top: 1px;
  left: calc(50% - 14px);
}

ion-modal.protocol-create {
  @media (max-width: 767px) {
    &::part(content) {
      top: 0;
      width: 100%;
      position: absolute;
      height: 480px;
    }
  }

  @media (max-height: 480px) {
    &::part(content) {
      height: 100%;
    }
  }
}

.attachment-menu-popover::part(content) {
  width: 170px !important;
}

.background-white {
  background: var(--ion-color-white-tint);
}

.dark .background-white {
  background: transparent;
}

// Expand ion-input to full height in project settings page
app-project-settings app-main-header .subheader ion-input {
  height: 100%;
}

.no-padding-start {
  --padding-start: 0px !important;
}

ion-header ion-toolbar ion-title {
  text-transform: none !important;
}

.item-disabled.item-has-value {
  opacity: 1 !important;

  & .ionic-selectable-icon {
    display: none;
  }
}

ion-modal.report-company-modal {
  @media (max-width: 767px) {
    &::part(content) {
      top: 0;
      position: absolute;
    }
  }
  --height: 350px;
}

ion-modal.report-activity-modal {
  @media (max-width: 767px) {
    &::part(content) {
      top: 0;
      position: absolute;
    }
  }
  --height: 500px;
}

ion-chip.connected-project {
  border-radius: 7px;
  font-size: 12px;
  padding: 7px;
  margin: 5px 0px;

  cursor: default;
  pointer-events: none;

  &:hover {
    background: none;
  }

  fa-icon {
    padding-right: 10px;
  }
}

html.ios app-main-header ion-title {
  position: static;
  @include one-line-ellipsis;
  padding-left: spacing(0.5);
  padding-right: spacing(0.5);
}

// Safari (and other safari-based devices, so iOS as well) bugfix
// For some reason this style: https://github.com/ionic-team/ionic-framework/blob/4b26feaa47efed4806aba565a52554db232b99e2/core/src/components/split-pane/split-pane.scss#L62
// takes precedence over styles below. More interestingly, Safari devtools
// shows that below scripts in original split-pane.scss have higher specificity
// but still gets discarded :thinking_face:
ion-split-pane.split-pane-visible > .split-pane-side:not(ion-menu) {
  display: flex;
  flex-shrink: 0;
}

fa-icon svg[data-prefix=bau][data-icon=paper-clip] path {
  fill: none;
  stroke: currentColor;
}

ion-loading {
  .loading-wrapper.loading-wrapper {
    @include normal-font;
    background: var(--ion-color-omg-modal-bg);
    color: var(--ion-color-neutral-light-badge-contrast);
  }
}

code-input input {
  padding: 0;
}

ion-accordion {
  background: #{$bmNeutral0};
  body.dark & {
    background: #{$bmNeutral9};
  }
  padding: spacing(0.75);
  border-radius: $omgBrandBorderRadiusBig;
  border: 1px solid var(--ion-color-omg-divider);
  box-shadow: $omgCardShadow;

  ion-item[slot="header"],
  .omg-form & ion-item.item[slot="header"],
  .omg-boundary .omg-form & ion-item.item[slot="header"] {
    @include large-font;
    font-weight: bold;
    color: $bmNeutral12;
    body.dark & {
      color: $bmNeutral1;
    }

    --min-height: auto;
  }

  ion-icon[slot="start"] {
    margin-top: 0;
    margin-bottom: 0;
  }
}

ion-modal.temperature-selectable {
  --min-width: 300px;
  --width: auto;
  --height: 400px;

  ion-item.ionic-selectable-item:has(.temperatureItemSelected) {
    --background: #{$bmNeutral3};
    :host-context(.dark) &, .dark & {
      --background: #{$bmNeutral7};
    }
  }
}

ion-textarea {
  // With upgrading to Ionic 7 ion-textarea was about two rows high initially, this changes is back to the previous behaviour
  min-height: 0 !important;
}

// After Ionic 8, split panel border has been moved to ion-menu.
// Since we don't use ion-menu in most of our cases, these rules are reverting that change.
ion-split-pane.split-pane-visible .split-pane-side {
  border-right: var(--border);
  @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    border-right: unset;
    -webkit-border-inline-end: var(--border);
    border-inline-end: var(--border);
  }

  min-width: var(--side-min-width);
  max-width: var(--side-max-width);
}

ion-split-pane.split-pane-visible .split-pane-side[side=end] {
  border-left: var(--border);
  @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    border-left: unset;
    -webkit-border-inline-start: var(--border);
    border-inline-start: var(--border);
  }

  min-width: var(--side-min-width);
  max-width: var(--side-max-width);
}

body.dark ion-modal.notifications-center-modal {
  &::part(content) {
    --ion-background-color: #{$bmNeutral11};
    --ion-item-background: #{$bmNeutral11};
    --ion-toolbar-background: #{$bmNeutral11};
    --background: #{$bmNeutral11};
  }

  app-notification-card {
    background: $bmNeutral10;
  }
}

ion-modal.half-screen-modal-with-units::part(content) {
  width: 100%;
  position: absolute;
  height: 465px;
  max-height: min(90vh, 100%);

  @media (max-height: 355px) {
    height: 100%;
  }

  @include breakpoint.breakpoint-up('md') {
    width: 80%;
    min-width: 670px;
    max-width: 1000px;
  }

  @include breakpoint.breakpoint-up('xl') {
    min-width: 1000px;
  }

  @include breakpoint.breakpoint-down('xl') {
    top: 0;
  }
}

ion-modal.protocol-entry-modal.half-screen-modal-with-units::part(content) {
  @include breakpoint.breakpoint-up('md') {
    max-width: 800px;
    width: 100%;
  }
}

.rx-virtual-scroll-viewport.auto-full-width .rx-virtual-scroll__runway {
  & > .reset-position {
    position: relative !important;
  }
  & > * {
    width: 100%;
  }
}

.one-line-ellipsis {
  @include one-line-ellipsis;
}

.unit-field-item {
  cursor: pointer;
  .unit-field-label {
    @include one-line-ellipsis;
    direction: rtl;
    text-align: left;
  }
  .ionic-selectable-label-floating {
    display: flex;
    gap: mixins.spacing(1);
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
    transform: translateY(-25%);
    padding: 0;
  }
}

.bau-radio {
  $radioSize: var(--bau-radio-size, 20px);
  $innerRadioSize: var(--bau-inner-radio-size, 10px);

  width: $radioSize;
  height: $radioSize;

  min-width: $radioSize;
  min-height: $radioSize;

  border: 2px solid $bmNeutral5;
  border-radius: $radioSize;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";

    width: $innerRadioSize;
    height: $innerRadioSize;

    min-width: $innerRadioSize;
    min-height: $innerRadioSize;

    border-radius: $innerRadioSize;

    background-color: $bmNeutral7;
    
    transform: scale(0);

    transition: transform 160ms ease 0s;
  }

  &.checked::after {
    transform: scale(1);
  }
}

.bau-checkbox {
  $checkboxSize: var(--bau-checkbox-size, 20px);

  &, &.clear-button {
    width: $checkboxSize;
  }
  height: $checkboxSize;

  min-width: $checkboxSize;
  min-height: $checkboxSize;

  border: 2px solid $bmNeutral5;
  border-radius: 2px;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  transition: background-color 160ms ease 0s,
              border-color 160ms ease 0s;
  font-size: 0;

  &::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cpath d='M1.73,12.91 8.1,19.28 22.79,4.59' stroke-dasharray='30' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' stroke='white' fill='none'%3E%3C/path%3E%3C/svg%3E");
    position: absolute;
    width: 90%;
    padding-top: 2px;
    transform: scale(0);
    transition: transform 160ms ease 0s;
    font-size: 0;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: white;
    height: 2px;
    width: 65%;
    border-radius: 2px;
    transform: scale(0);
    transition: transform 160ms ease 0s;
    font-size: 0;
  }

  &.checked,
  &.indeterminate,
  .bau-checkbox--checked & {
    background-color: $bmNeutral7;
    border-color: $bmNeutral7;
  }

  &.checked::after,
  .bau-checkbox--checked &::after {
    transform: scale(1);
  }

  &.indeterminate::before {
    transform: scale(1);
  }
}
