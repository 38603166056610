@use './mixins';
@use './typography';
@use './tokens/attention';

@mixin omg-attention-base {
  padding: mixins.spacing(0.5) mixins.spacing(0.75);
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  @include typography.omg-body;
}

@mixin omg-attention-color($def) {
  $lm: map-get($def, 'lm');
  $dm: map-get($def, 'dm');
  $bgLm: map-get($lm, 'background');
  $colorLm: map-get($lm, 'color');
  $bgDm: map-get($dm, 'background');
  $colorDm: map-get($dm, 'color');

  background-color: $bgLm;
  color: $colorLm;

  body.dark & {
    background-color: $bgDm;
    color: $colorDm;
  }
}

@mixin omg-attention {
  @include omg-attention-base;

  &-danger {
    @include omg-attention-color(attention.$attentionDanger);
  }
  &-warning {
    @include omg-attention-color(attention.$attentionWarning);
  }
}
