@import "./color";
@import "./mixins";
@import "./variables";

@mixin bau-card() {
  border-radius: $bauBorderRadiusMedium;

  box-shadow: $bmSlimCardBoxShadow;

  .show-more {
    display: flex;
    justify-content: center;
  }

  ion-list {
    $veritcalPadding: 10px;
    $horizontalPadding: 18px;
    padding-top: $veritcalPadding;
    padding-bottom: $veritcalPadding;

    &.no-padding-bottom {
      padding-bottom: 0;
    }

    ion-list-header,
    ion-item {
      --padding-start: #{$horizontalPadding} !important;
      --padding-end: #{$horizontalPadding} !important;

      align-items: center;
    }

    ion-list-header {
      justify-content: space-between;

      font-size: 18px;
      color: $bmPrimary;
      font-weight: 700;

      margin-top: -7px;

      padding-inline-start: var(--padding-start);
      padding-inline-end: var(--padding-end);

      .heading {
        flex-grow: 1;
        @include one-line-ellipsis;
      }
    }

    + .show-more {
      margin-top: -$veritcalPadding;
    }
  }

  .dark & {
    background: var(--ion-color-elevated-alt);
    color: var(--ion-color-elevated-alt-contrast);

    ion-list {
      background: var(--ion-color-elevated-alt);

      ion-item {
        --background: var(--ion-color-elevated-alt);
      }
    }
  }
}

ion-card.bau-card {
  @include bau-card();
}
